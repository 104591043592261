// TODO: comment
import { Box, Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { getCountryValue, getInvestorTypeValue } from 'api';
import { useSecurity } from 'authentication';
import { Anchor } from 'components/core';
import { DataList } from 'components/data-list';
import { HelpBox } from 'components/help';
import { Breadcrumbs } from 'components/navigation';
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, selectUserDetails } from 'redux/reducers/account';
import { delayFire, getSpecificFormattedDate } from 'utils';
import { AccountsListing } from './components';
import { Link as MaterialLink } from '@material-ui/core';

const PageAccount = () => {
  const { t } = useTranslation(['common']);

  const { settings } = useSecurity();

  const dispatch = useDispatch();
  const details = useSelector(selectUserDetails);

  const [investorType, setInvestorType] = useState<string>('');
  const [country, setCountry] = useState<string>('');

  useEffect(() => {
    const load = async () => {
      const investorType = await getInvestorTypeValue(details.investorType);
      setInvestorType(investorType);

      const country = await getCountryValue(details.country);
      setCountry(country);
    };

    load();
  }, [details]);

  // when the user is fully authenticated, we need to fire a request to get the users details
  useEffect(() => {
    delayFire(() => {
      dispatch(getUserDetails());
    });
  }, []);

  // return the view
  return (
    <Fragment>
      <Helmet>
        <title>{t('common:titles.account')}</title>
      </Helmet>

      <Breadcrumbs
        trail={[{ label: 'Home', href: '/' }, { label: 'My Details' }]}
      />

      <Box paddingY={6}>
        <Container>
          <Typography variant="h3" component="h1">
            {t('common:titles.account')}
          </Typography>

          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box pt={3} mr={3} ml={3} pb={3} style={{ borderBottom: '1px solid #D9D9D6' }}>
                  <Typography variant="h4" component="h2">
                    Details
                  </Typography>

                  <DataList
                    items={{
                      Title: details.title,
                      Forename: details.forename,
                      Surname: details.surname,
                      Salutation: details.salutation,
                      'Preferred Name': details.preferredName,
                      Logon: details.logon,
                      Email: details.emailAddress,
                    }}
                  />

                  {details.lastLoginDate && (
                    <Box marginTop={6}>
                      <DataList
                        items={{
                          'Last Login': getSpecificFormattedDate(
                            details.lastLoginDate
                          ),
                        }}
                      />
                    </Box>
                  )}
                </Box>

                <Box padding={3}>
                  <Typography variant="h5" style={{ marginBottom: 4 }}>
                    Reset password
                  </Typography>

                  <Typography variant="body2">
                    You will be signed out of your account, redirected to reset your password, and you will need to sign in again using your new credentials.
                  </Typography>

                  <Box mt={3}>
                    {/* @ts-ignore */}
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      href="https://dev-secure.abrdn.com/signin/forgot-password"
                      component={MaterialLink}
                      style={{ textDecoration: 'none', color: '#fff' }}
                    >
                      Reset Password
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box padding={3}>
                  <Typography variant="h4" component="h2">
                    Locale
                  </Typography>
                  <DataList
                    items={{
                      Region: details.region,
                      Country: country,
                      'Investor Type': investorType,
                    }}
                  />
                </Box>
              </Paper>
              <HelpBox title="Need to update your details?">
                <Typography variant="body2">
                  Submit an 'Update my details' request in
                  {' '}
                  <Anchor to="/my-tasks" style={{ fontWeight: 700 }}>
                    My Tasks
                  </Anchor>
                  {' '}
                  and our team will help you update your account details
                </Typography>
              </HelpBox>
            </Grid>
          </Grid>
        </Container>

        {!settings['ClientHoldings.Enabled'] && (
          <Box paddingTop={6}>
            <Container>
              <AccountsListing />
            </Container>
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default PageAccount;
