// TODO: comment
import { useRouter } from '@abrdn-latest/use';
import { Box, Typography } from '@material-ui/core';
import { useSecurity } from 'authentication';
import { Confirmation } from 'components/confirmation';
import { canSetEssentialCookies } from 'components/cookies';
import React, { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActionRequired,
  selectActionRequiredCount,
} from 'redux/reducers/messages';
import { delayFire } from 'utils';

// cookie name to store
export const COOKIE_NAME: string = 'actionRequiredDismissed';

export const deleteActionRequiredCookie = () => {
  const cookies = new Cookies();

  cookies.remove(COOKIE_NAME);
};

export const ActionRequiredModal = () => {
  const { authState } = useSecurity();

  const cookies = new Cookies();

  const router = useRouter();
  const dispatch = useDispatch();
  // get the number of messages that need action required
  const action = useSelector(selectActionRequiredCount);

  // only fire checking events when the user is fully authenticated
  useEffect(() => {
    if (authState.isAuthenticated) {
      delayFire(() => {
        dispatch(getActionRequired());
      });
    }
  }, [authState.isAuthenticated]);

  if (!authState.isAuthenticated) {
    return null;
  }

  /**
   * function to flag the message as 'seen' on a session basis
   */
  const dismiss = () => {
    if (canSetEssentialCookies()) {
      cookies.set(COOKIE_NAME, 'on', { path: '/' });
    }
  };

  /**
   *
   */
  const handleReject = () => {
    dismiss();
  };

  /**
   *
   */
  const handleConfirm = () => {
    dismiss();

    router.history.push('/messages/action-required');
  };

  return (
    <Confirmation
      isOpen={!!(action && !cookies.get(COOKIE_NAME))}
      rejectText="View later"
      onReject={handleReject}
      confirmText="View now"
      onConfirm={handleConfirm}
    >
      <Box textAlign="center">
        <Typography variant="h4">Just to remind you.</Typography>
        <Typography paragraph>
          You have message(s) that require your action
        </Typography>
      </Box>
    </Confirmation>
  );
};
