// TODO: comment
import { Box, Container, Paper, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import { useSecurity } from 'authentication';
import { Anchor } from 'components/core';
import { useTranslation } from 'react-i18next';
import { ThemeDark } from 'styles';
import { formatString } from 'utils';
import './footer.scss';

export const Footer = () => {
  const { authState } = useSecurity();
  const { t } = useTranslation(['common']);

  if (authState.isPending || !authState.isAuthenticated) {
    return null;
  }

  return (
    <ThemeDark>
      <Paper className="footer">
        <Box paddingY={5}>
          <Container>
            <Typography variant="h5" component="p">
              {formatString(t('common:footer.copyright'), {
                year: new Date().getFullYear(),
              })}
            </Typography>
            <List>
              <li>
                <Anchor to="/terms-and-conditions">{t('nav.terms')}</Anchor>
              </li>

              <li>
                <a
                  rel="noopener noreferrer"
                  href="https://www.abrdn.com/corporate/cookie-policy"
                  target="_blank"
                  style={{ color: 'inherit' }}
                >
                  {t('nav.cookie-policy')}
                </a>
              </li>

              <li>
                <a
                  rel="noopener noreferrer"
                  href="https://www.abrdn.com/en/uk/institutional/privacy"
                  target="_blank"
                  style={{ color: 'inherit' }}
                >
                  Privacy
                </a>
              </li>
            </List>
            <Box maxWidth={720}>
              <Typography
                paragraph
                variant="caption"
                dangerouslySetInnerHTML={{
                  __html: t('common:footer.disclaimer'),
                }}
              />
            </Box>
          </Container>
        </Box>
      </Paper>
    </ThemeDark>
  );
};
