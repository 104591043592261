// TODO: comment
import {
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useSecurity } from 'authentication';
import { Anchor } from 'components/core';
import { AccountCircle } from 'icons';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getUserDetails } from 'redux/reducers/account';
import { ThemeDefault } from 'styles';
import { delayFire } from 'utils';

export const UserDetails = () => {
  const { t } = useTranslation(['common']);

  const { authMethods } = useSecurity();
  const { authState } = useSecurity();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  function handleListKeyDown(ev: any) {
    if (ev.key === 'Tab') {
      ev.preventDefault();
      setOpen(false);
    }
  }

  const handleClose = (ev: any) => {
    // @ts-ignore
    if (anchorRef.current && anchorRef.current.contains(ev.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = async (ev: React.SyntheticEvent) => {
    ev.preventDefault();

    authMethods.signOut();

    setOpen(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (authState.isAuthenticated) {
      delayFire(() => {
        dispatch(getUserDetails());
      });
    }
  }, [authState.isAuthenticated]);

  if (authState.isAuthenticated === false) {
    return null;
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Fragment>
      <Tooltip title="My Connect" placement="left">
        <IconButton
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{ color: 'inherit' }}
        >
          <AccountCircle />
        </IconButton>
      </Tooltip>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <ThemeDefault>
              <Paper elevation={2}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    className="popmenu"
                  >
                    <MenuItem>
                      <Anchor
                        to="/account"
                        color="primary"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        {t('nav.account')}
                      </Anchor>
                    </MenuItem>
                    <MenuItem>
                      <Anchor
                        to="/logout"
                        color="primary"
                        onClick={handleLogout}
                      >
                        {t('nav.logout')}
                      </Anchor>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </ThemeDefault>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};
