// TODO: comment
import { sendApiRequest } from '../utils';
import { isAuthenticatedResponse } from './types';

interface LoginRequest {
  password: string;
  username: string;
  validationToken: string;
}

export const login = async ({
  username,
  password,
  validationToken = '',
}: LoginRequest): Promise<isAuthenticatedResponse> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/account/login`;

  const response = await sendApiRequest<isAuthenticatedResponse>(
    endpoint,
    null,
    {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
        validationToken,
      }),
      headers: { 'Content-Type': 'application/json' },
    }
  );

  return response;
};
