// TODO: comment
import { Fade, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './Splash.scss';
import { makeStyles } from '@material-ui/core/styles';
import { getPublicPath } from '../../utils/assets';

interface Props {
  alt: string;
  fadeOut?: boolean;
  logo: string;
}

const useStyles = makeStyles((theme) => ({
  img: {
    position: 'relative',
    overflow: 'hidden',
    width: 260,
    height: 56,
    backgroundImage: (props: Props) => `url("${props.logo}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
}));

export const Splash = (props: Props) => {
  const [visible, setVisible] = useState(true);
  const classes = useStyles(props);

  useEffect(() => {
    if (props.fadeOut) {
      setTimeout(() => {
        setVisible(false);
      }, 1500);
    }
  }, [props.fadeOut]);

  return (
    <Fade in={visible}>
      <Paper className="app-loader">
        <div className={classes.img} />
      </Paper>
    </Fade>
  );
};
