// TODO: comment
import { AppProviderWrapper } from '@abrdn-latest/providers';
import DayJsUtils from '@date-io/dayjs';
import { Box, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import AppRoutes from './AppRoutes';
import { SecureRoute, Security, useSecurity } from 'authentication';
import { ActionRequiredModal } from 'components/action-required';
import { Connectivity } from 'components/connectivity';
import { ErrorBoundary } from 'components/error-boundary';
import { Footer } from 'components/footer';
import { Header } from 'components/header';
import { ScrollToTop } from 'components/scroll-to-top';
import { Unauthorized } from 'components/unauthorized';
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { AccountRoutes } from 'pages/account';
import { AuthRoutes } from 'pages/authentication';
import { AccountsRoutes } from 'pages/client-holdings';
import { ClientHoldings } from 'pages/client-holdings/data';
import { DocumentRoutes } from 'pages/documents';
import PageError from 'pages/error/PageError';
import { ESGRoutes } from 'pages/esg';
import PageFundCentre from 'pages/fund-centre/PageFundCentre';
import PageHome from 'pages/home/PageHome';
import { MessagePopup, MessageRoutes } from 'pages/messages';
import Page404 from 'pages/not-found/PageNotFound';
import { OurThinkingRoutes } from 'pages/our-thinking';
import { PortfolioHoldingsRoutes } from 'pages/portfolio-holdings';
import { PortfolioHoldings } from 'pages/portfolio-holdings/data';
import PageTermsAndConditions from 'pages/terms-and-conditions/PageTermsAndConditions';
import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { HashRouter, BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper.scss';
import { useDispatch } from 'react-redux';
import { getUnreadCount } from './redux/reducers/messages';
import { SERVER_ACTION_DELAY } from './api/constants';
import { debounce } from './@abrdn-latest/utils';
import { getPublicPath } from './utils/assets';
import { LoginCallback } from '@okta/okta-react';
import { OktaSecurity } from './authentication/OktaSecurity';

const Routes = () => {
  const dispatch = useDispatch();
  const { authState, settings } = useSecurity();
  const { pathname } = useLocation();

  const debouncedDispatch = debounce(dispatch, SERVER_ACTION_DELAY * 3);

  // temporary hot fix until proper redux message handling is implemented
  useEffect(() => {
    if (authState.isAuthenticated) {
      debouncedDispatch(getUnreadCount());
    }
  }, [pathname, authState.isAuthenticated]);

  if (authState.isPending) {
    return null;
  }

  return (
    <Fragment>
      <SecureRoute exact path="/" component={PageHome} />
      <AuthRoutes />
      <SecureRoute path="/fund-centre" component={PageFundCentre} />
      <OurThinkingRoutes />
      <PortfolioHoldingsRoutes />
      <MessageRoutes />
      <DocumentRoutes />
      <ESGRoutes />
      <AccountRoutes />
      {settings['ClientHoldings.Enabled'] && <AccountsRoutes />}
      <Route path="/terms-and-conditions" component={PageTermsAndConditions} />
      <Route exact path="/error/:code" component={PageError} />
    </Fragment>
  );
};

const CookieConfig = () => {
  const { authState, settings } = useSecurity();

  if (authState.isPending) {
    return null;
  }

  return (
    <Helmet>
      <script
        type="text/javascript"
        src={`https://cdn-ukwest.onetrust.com/consent/${settings['OneTrustID']}/OtAutoBlock.js`}
      ></script>
      <script
        src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
        type="text/javascript"
        // @ts-ignore
        charset="UTF-8"
        data-domain-script={settings['OneTrustID']}
      ></script>

      <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
    </Helmet>
  );
};

// New
const AppMain = ({ brand }: { brand: any }) => {
  return (
    <AppProviderWrapper>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <Security brand={brand}>
          <CookieConfig />
          <ClientHoldings>
            <PortfolioHoldings>
              <ScrollToTop />
              <Header {...brand} />
              <Box className="main">
                <ErrorBoundary>
                  <Switch>
                    <Routes />
                    <Route path="*" component={Page404} />
                  </Switch>
                </ErrorBoundary>
              </Box>
              <ActionRequiredModal />
              <MessagePopup />
              <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
              />

              <Footer />
            </PortfolioHoldings>
          </ClientHoldings>
        </Security>
      </MuiPickersUtilsProvider>
    </AppProviderWrapper>
  );
};

function App() {
  const { t } = useTranslation(['common']);

  const brand = {
    alt: t('title'),
    logo: getPublicPath('images/abrdn.svg'),
  };

  return (
    <Box className="app">
      <CssBaseline />
      <Helmet titleTemplate={`abrdn Connect - %s`}>
        <link
          rel="icon"
          type="image/x-icon"
          href={getPublicPath('favicon.ico')}
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href={getPublicPath('apple-touch-icon.png')}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={getPublicPath('favicon-32x32.png')}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={getPublicPath('favicon-16x16.png')}
        />
        <link
          rel="mask-icon"
          href={getPublicPath('safari-pinned-tab.svg')}
          color="#5BBAD5"
        />
      </Helmet>
      <Connectivity />
      <Unauthorized />
      <OktaSecurity brand={brand}>
        <HashRouter>
          <AppMain brand={brand} />
        </HashRouter>
        <BrowserRouter>
          <Route path="*" component={LoginCallback} />
        </BrowserRouter>
      </OktaSecurity>
    </Box>
  );
}

export default App;
