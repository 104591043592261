// TODO: comment
import { useRouter } from '@abrdn-latest/use';
import { useOktaAuth } from '@okta/okta-react';
import {
  getAppSettings,
  getInvestorTypes,
  getUserDetails,
  login,
  setLogout,
  userStatus
} from 'api';
import { AuthenticationActions, isAuthenticatedResponse, SalesforceAuthActions } from 'api/auth/types';
import { deleteActionRequiredCookie } from 'components/action-required';
import { canSetPerformanceCookies } from 'components/cookies';
import { Splash } from 'components/splash';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { deleteSessionStorageItems, sha256 } from 'utils';
import { SecurityProvider } from './';
import { getOktaCredentials } from '../api/auth/oktaCredentials';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  brand: any;
  children: React.ReactNode;
}

let lastTrack: string = '';
let isGAInitialized = false;

export const Security = ({ children, brand }: Props) => {
  const { oktaAuth, authState: oktaAuthState } = useOktaAuth();

  const router = useRouter();

  const [settings, setSettings] = useState<any | null>(null);

  const [authenticated, setAuthenticated] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [error, setError] = useState(false);

  const [authScreens, setAuthScreens] = useState<AuthenticationActions>({
    memorableQuestionsResetRequired: false,
    passwordResetRequired: false,
    oneTimePasswordRequired: false,
  });

  const [salesforceAuth, setSalesforceAuth] = useState<SalesforceAuthActions>();

  const [salesforceUrl, setSalesforceUrl] = useState<string>();

  const [authMessage, setAuthMessage] = useState<string | null>(null);

  function setupScreens(actions: isAuthenticatedResponse) {
    setAuthScreens({
      memorableQuestionsResetRequired: actions.memorableQuestionsResetRequired,
      passwordResetRequired: actions.passwordResetRequired,
      oneTimePasswordRequired: actions.oneTimePasswordRequired,
    });

    setSalesforceAuth({
      identityIdentifier: actions.identityIdentifier !== 'Blank'
        ? actions.identityIdentifier
        : '',
      customerIdentifier: actions.customerIdentifier !== 'Blank'
        ? actions.customerIdentifier
        : '',
      customerRelationshipIdentifier: actions.customerRelationshipIdentifier !== 'Blank'
        ? actions.customerRelationshipIdentifier
        : '',
    });

    if (actions.authenticationStateMessage) {
      setAuthMessage(actions.authenticationStateMessage);
    }
  }

  async function trackPage() {
    // if we're on the homepage and not authenticated, don't track, as there will be a redirect
    if (
      (router.location.pathname === '/' && !authenticated) ||
      !canSetPerformanceCookies()
    ) {
      return;
    }

    if (settings) {
      //
      initializeTagManager(settings);

      const dataLayer = (window.dataLayer = window.dataLayer || []);
      let details = null;

      if (authenticated) {
        details = await getUserDetails();
      }

      var payload = {
        environment: {
          setting: settings['Environment'],
          appType: 'web',
          timestamp: dayjs.utc().local().format(),
        },
        site: {
          country: 'uk',
          region: 'emea',
          language: 'en',
          vector: 'investment',
        },
        user: {
          loginId: details ? details.partyReference || '' : '',
          emailAddressHash: details?.emailAddress ? sha256(details.emailAddress) : '',
        },
        page: {
          pageUrl: router.location.pathname + router.location.search,
          pageTitle: document.title,
        },
        event: 'pageLoaded',
      };

      const id: string = JSON.stringify(payload);

      if (id !== lastTrack) {
        dataLayer.push(payload);
      }

      lastTrack = id;
    }
  }

  function initializeTagManager(settings: any) {
    // Google Analytics
    if (
      settings['GoogleAnalytics.Enabled'] &&
      settings['GoogleAnalytics.TrackingId'] &&
      canSetPerformanceCookies() &&
      !isGAInitialized
    ) {
      const tagManagerArgs = {
        gtmId: settings['GoogleAnalytics.TrackingId'],
      };

      TagManager.initialize(tagManagerArgs);

      isGAInitialized = true;
    }
  }

  async function removeError() {
    const isOktaAuthenticated = await oktaAuth.isAuthenticated();
    if (isOktaAuthenticated) {
      await oktaAuth.signOut();
    }
    setError(false);
  }

  useEffect(() => {
    const load = async () => {
      try {
        const status = await userStatus();

        const settings = await getAppSettings();

        const { salesforceUrl } = await getOktaCredentials();

        await getInvestorTypes();

        // await setOktaAuth();

        // start tracking
        initializeTagManager(settings);

        if (status.isAuthenticated) {
          setAuthenticated(true);
          setupScreens(status);
        }

        setSettings(settings);
        setSalesforceUrl(salesforceUrl);
      } catch (e) {
        setError(true);
      }
    };

    load();
  }, []);

  // Analytics
  useEffect(() => {
    setTimeout(trackPage, 1);
  }, [settings, router.location]);

  useEffect(() => {
    async function runEffect() {
      const isOktaAuthenticated = await oktaAuth.isAuthenticated();
      if (router.location.pathname === '/auth/landing' && authenticated && isOktaAuthenticated) {
        window.location.replace('#/');
      }
      if (
        router.location.pathname !== '/auth/landing' && router.location.pathname !== '/auth/register'
        && !authenticated && !isOktaAuthenticated
      ) {
        router.replace('/auth/landing');
      }
    }
    runEffect();
  }, [router.location.pathname, authenticated]);

  useEffect(() => {
    async function runEffect() {
      if (!oktaAuthState?.isAuthenticated) {
        return;
      }
      const user = await oktaAuth.token.getUserInfo();
      if (user?.email) {
        setLoadingLogin(true);
        try {
          const res = await login({
            username: user.email, // @ts-ignore
            password: self.crypto.randomUUID(),
            validationToken: oktaAuthState.accessToken.accessToken,
          });

          if (res) {
            await userStatus();
            setupScreens(res);
            setAuthenticated(res.isAuthenticated);
          }
        } catch (e) {
          setError(true);
        } finally {
          setLoadingLogin(false);
        }
      }
    }
    runEffect();
  }, [oktaAuthState?.isAuthenticated]);

  if (settings === null || !oktaAuth || loadingLogin) {
    return <Splash {...brand} />;
  }

  return (
    <>
      <Helmet></Helmet>
      <Splash {...brand} fadeOut={true} />
      <SecurityProvider
        value={{
          authState: {
            // possibly set this to true in only when screens.oneTimePasswordRequired = false
            isAuthenticated: authenticated,
            isPending: false,
            screens: authScreens,
            salesforceAuth,
            salesforceUrl,
            authMessage,
            error,
          },
          settings,
          authMethods: {
            removeError,
            setScreens: (actions: isAuthenticatedResponse) => {
              // set the screens up
              setupScreens(actions);

              if (typeof actions.isAuthenticated === 'boolean') {
                // oneTimePassword may be undefined of a boolean, so do not perform a strict check
                setAuthenticated(
                  actions.isAuthenticated === true &&
                    !actions.oneTimePasswordRequired
                );
              }
            },
            signOut: async () => {
              // only if the user is fully authenticated cam they call the logout endpoint
              // without generating an unauthorized error
              if (authenticated) {
                await setLogout();
              }

              await oktaAuth.signOut();

              // remove anction required flag cookie
              deleteActionRequiredCookie();

              // remove the session storage token
              if (typeof Storage !== 'undefined') {
                localStorage.removeItem('session');
              }

              // clear all session storage items
              deleteSessionStorageItems();

              // on logout, make another request to the anti-forgery token is refreshed
              await userStatus();

              // if (authenticated) {
              //   // reload the app to clear all the data down
              //   window.location.reload();
              // }

              // fully sign the user out of the app
              setAuthenticated(false);

              //
              // window.location.reload();
            },
          },
        }}
      >
        {children}
        {/*<SecurityModal />*/}
      </SecurityProvider>
    </>
  );
};
