// TODO: comment

import { Badge, IconButton, List, Tooltip } from '@material-ui/core';
import { getUnreadCount, selectUnreadCount } from 'redux/reducers/messages';
import { useDispatch, useSelector } from 'react-redux';

import { ListItemLink } from 'components/navigation';
import { MailIcon } from 'icons';
import { NavLink } from 'react-router-dom';
import { UserDetails } from 'components/user-details';
import { delayFire } from 'utils';
import { useClientHoldings } from 'pages/client-holdings/data';
import { useEffect, useState } from 'react';
import { useSecurity } from 'authentication';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { getUserTaskCount } from '../../api/client-provisioning/getUserTaskCount';

interface Props {
  isMobile?: boolean;
}

// @ts-ignore
const useStyles = makeStyles(() => ({
  myTasksItem: {
    '& a div:nth-child(2)': {
      minWidth: 0,
    },
  },
  badgeWrapper: {
    position: 'absolute',
    top: 0,
    marginLeft: 4,
  },
  badge: {
    '& span': {
      fontSize: '0.8571428571428571rem !important',
      fontWeight: 'initial !important',
    },
  },
}));

export const PrimaryNavigation = ({ isMobile }: Props) => {
  // TODO
  const { clients } = useClientHoldings();

  const styles = useStyles();

  const { t } = useTranslation(['common']);

  const [taskCount, setTaskCount] = useState(0);

  const unreadCount = useSelector(selectUnreadCount);

  const dispatch = useDispatch();
  const { authState, settings } = useSecurity();
  const salesforceCustomerId = authState.salesforceAuth?.customerIdentifier;

  useEffect(() => {
    if (authState.isAuthenticated) {
      delayFire(async () => {
        dispatch(getUnreadCount());
        if (salesforceCustomerId) {
          const { taskCount } = await getUserTaskCount(salesforceCustomerId);
          setTaskCount(taskCount);
        }
      });
    }
  }, [authState.isAuthenticated, salesforceCustomerId]);

  return (
    <List>
      <ListItemLink exact={true} to="/" primary={t('nav.home')} />
      <ListItemLink
        isTopLevel
        to="/our-thinking"
        primary={t('nav.our-thinking')}
      />
      <ListItemLink
        isTopLevel
        to="/fund-centre"
        primary={t('nav.fund-centre')}
      />
      <ListItemLink
        isTopLevel
        to="/portfolio-holdings"
        primary={t('nav.portfolio-holdings')}
      />
      <ListItemLink isTopLevel to="/documents" primary={t('nav.documents')} />

      {/* if client holdings is enabled, and there are accounts to show */}
      {settings['ClientHoldings.Enabled'] && clients.length > 0 && (
        <ListItemLink
          isTopLevel
          to={`/accounts/${clients[0].id}/accounts`}
          primary={t('nav.accounts')}
        />
      )}

      <ListItemLink
        external
        isTopLevel
        to={authState.salesforceUrl}
        primary={t('nav.my-tasks')}
        className={styles.myTasksItem}
        iconEnd={taskCount && (
          <div className={styles.badgeWrapper}>
            <Badge badgeContent={taskCount} color="error" className={styles.badge} />
          </div>
        )}
      />

      {isMobile ? (
        <ListItemLink
          isTopLevel
          to="/messages/inbox"
          primary={t('nav.messages')}
          iconEnd={
            unreadCount ? (
              <Badge badgeContent={unreadCount} color="error">
                <MailIcon />
              </Badge>
            ) : (
              <MailIcon />
            )
          }
        />
      ) : (
        <Tooltip title="Messages">
          <IconButton
            component={NavLink}
            to="/messages/inbox"
            style={{ color: 'inherit' }}
          >
            {unreadCount ? (
              <Badge badgeContent={unreadCount} color="error">
                <MailIcon />
              </Badge>
            ) : (
              <MailIcon />
            )}
          </IconButton>
        </Tooltip>
      )}

      {!isMobile && <UserDetails />}
    </List>
  );
};
