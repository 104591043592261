// TODO: comment

import { States } from '@abrdn-latest/config';
import { SearchInput, Stack } from '@abrdn-latest/core';
import {
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DocumentOnlyClientDetail, getSavedFundList, UserClientDetail } from 'api';
import { Valuation } from 'api/client-holdings/types';
import { Fund } from 'api/portfolio-holdings/types';
import { useSecurity } from 'authentication';
import { Anchor } from 'components/core';
import { Loading } from 'components/no-data';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DarkDocumentOnlyClientIcon,
  GridOnIcon,
  StarIcon,
  TableChartIcon,
} from 'icons';
import { Banner } from 'pages/home';
import { usePortfolioHoldings } from 'pages/portfolio-holdings/data';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import { ThemeDark, ThemeDefault } from 'styles';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  escapeStringForRegEx,
  formatNumber,
  getLocalStorageItem,
  getSessionStorageItem,
  setLocalStorageItem,
  setSessionStorageItem,
} from 'utils';
import {
  useMessageComposerDispatch,
  useMessageComposerState,
} from 'utils/message-context';
import { DocumentOnlyTile, FavouriteFundTile, Quick, Tile } from './';
import { useClientHoldings } from '../data';

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

enum View {
  Tabular = 'tabular',
  Tile = 'tile',
}

interface AddAdditionalHoldingsProps {
  isTile?: boolean;
}

const AddAdditionalHoldings = ({
  isTile = false,
}: AddAdditionalHoldingsProps) => {
  const dispatch = useMessageComposerDispatch();
  const { state } = useMessageComposerState();

  const handleClick = (ev: React.MouseEvent) => {
    ev.preventDefault();

    // @ts-ignore
    dispatch({
      type: 'open',
      // type: state === 'open' ? 'minimised' : 'open',
      props: { subjectDisplayText: 'View My abrdn Holdings' },
    });
  };

  return (
    <ThemeDark>
      <Box p={4} component={Paper}>
        <Typography variant="h5" style={isTile ? { marginBottom: 5 } : {}}>
          Do you have holdings with us?
        </Typography>
        <Typography>
          Get in touch to add your holdings here{' '}
          {isTile ? '' : 'for easy access to valuations and reporting.'}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          component={RouterLink}
          to={'/message/new'}
          size="large"
          style={{ marginTop: 15, fontSize: 'inherit' }}
        >
          Add holdings
        </Button>
      </Box>
    </ThemeDark>
  );
};
const FavouriteMessage = () => {
  return (
    <Box p={4} component={Paper}>
      <Typography variant="h5">Favourite something...</Typography>
      <Typography>
        Get in touch to add your holdings here for easy access to valuations and
        reporting.
      </Typography>
    </Box>
  );
};

interface AdditionalBoxesProps {
  showFavourites?: boolean;
}

const AdditionalBoxes = ({ showFavourites = false }: AdditionalBoxesProps) => {
  const { settings } = useSecurity();

  if (!showFavourites && !settings['ClientHoldings.Enabled']) {
    return null;
  }

  return (
    <Box mt={6}>
      <Grid container spacing={3} direction="row" alignItems="flex-start">
        {settings['ClientHoldings.Enabled'] && (
          <Grid item xs={12} md={6}>
            <AddAdditionalHoldings />
          </Grid>
        )}
        {showFavourites && (
          <Grid item xs={12} md={6}>
            <FavouriteMessage />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

type Holding = UserClientDetail | DocumentOnlyClientDetail | Fund | null;

export const AccountsWidget = () => {
  // pagination size, this is used to split tiles up into a scrollble area
  const PAGE_SIZE: number = 8;

  const { settings } = useSecurity();

  const { loading, clients, documentOnlyClients } = useClientHoldings();

  const { funds } = usePortfolioHoldings();

  // clients
  const [displayedClients, setDisplayedClients] = useState<UserClientDetail[]>([]);

  // clients
  const [displayedDocumentOnlyClients, setDisplayedDocumentOnlyClients] =
    useState<DocumentOnlyClientDetail[]>([]);

  // saved funds
  const [savedFunds, setSavedFunds] = useState<Fund[]>([]);
  const [displayedSavedFund, setDisplayedSavedFund] = useState<Fund[]>([]);

  const holdings = [...clients, ...documentOnlyClients, ...savedFunds];
  const displayedHoldings = [
    ...displayedClients,
    ...displayedDocumentOnlyClients,
    ...displayedSavedFund,
    null,
  ];

  useEffect(() => {
    if (clients) {
      setDisplayedClients(clients);

      if (clients.length > 0 && !selectedAccountId) {
        setSelectedAccountId(clients[0].id);
      }
    }
  }, [clients]);

  useEffect(() => {
    if (documentOnlyClients) {
      setDisplayedDocumentOnlyClients(documentOnlyClients);
    }
  }, [documentOnlyClients]);

  useEffect(() => {
    if (funds) {
      (async function fetchSavedFunds() {
        const savedFundCodes = await getSavedFundList();
        const savedFunds = funds.filter(fund => savedFundCodes.includes(fund.fundCode));
        setSavedFunds(savedFunds ?? []);
        setDisplayedSavedFund(savedFunds ?? []);
      })();
    }
  }, [funds]);

  const [tableRows, setTableRows] = useState<number>(PAGE_SIZE || 8);
  const [page, setPage] = useState<number>(0);

  const handleLoadMore = (ev: React.MouseEvent) => {
    setTableRows(Math.min(tableRows + PAGE_SIZE, displayedHoldings.length));
  };

  const [selectedAccountId, setSelectedAccountId] = useState<string>(
    getSessionStorageItem<string>('homepage-tile', '')
  );

  useEffect(() => {
    setSessionStorageItem('homepage-tile', selectedAccountId);
  }, [selectedAccountId]);

  let swiperInstance: any;

  // get the layout view

  const [view, setView] = useState<View>(
    getLocalStorageItem<View>('homepage-view', View.Tile)
  );

  const handleTileView = () => setView(View.Tile);

  const handleTabularView = () => setView(View.Tabular);

  useEffect(() => {
    setLocalStorageItem('homepage-view', view);
  }, [view]);

  const [filter, setFilter] = useState<string>('');

  const handleSearch = (value: string) => {
    setFilter(value);
  };

  const isClient = (obj: UserClientDetail | Fund | null): obj is UserClientDetail => {
    return Boolean(obj && 'state' in obj && !('documentOnly' in obj));
  }

  const isDocumentOnlyClient = (obj: DocumentOnlyClientDetail | Fund | null): obj is DocumentOnlyClientDetail => {
    return Boolean(obj && 'state' in obj && 'documentOnly' in obj);
  }

  const isFund = (obj: UserClientDetail | Fund | null): obj is Fund => {
    return Boolean(obj && 'fundCode' in obj);
  }

  useEffect(() => {
    if (filter) {
      const regEx: any = new RegExp(escapeStringForRegEx(filter) || '', 'i');

      const filteredClients = clients.filter((item) => {
        return item.preferredName.match(regEx) !== null;
      });

      setDisplayedClients(filteredClients);

      const filteredDocumentOnlyClients = documentOnlyClients.filter((item) => {
        return item.preferredName.match(regEx) !== null;
      });

      setDisplayedDocumentOnlyClients(filteredDocumentOnlyClients);

      const filteredSavedFunds = savedFunds.filter((item) => {
        return item.fundName.match(regEx) !== null;
      });

      setDisplayedSavedFund(filteredSavedFunds);

      return;
    }

    setDisplayedClients(clients);
    setDisplayedDocumentOnlyClients(documentOnlyClients);
    setDisplayedSavedFund(savedFunds);
  }, [filter]);

  const getSelectedClient = (): UserClientDetail | undefined => {
    const filtered = clients.filter((client) => {
      return client.id === selectedAccountId;
    });

    if (filtered.length) {
      return filtered[0];
    }

    return undefined;
  };

  const GenericTile = ({ holding }: { holding: Holding }) => {
    if (isClient(holding)) {
      return (
        <Tile
          client={holding}
          onMoreDetails={() => setSelectedAccountId(holding.id)}
        />
      );
    }
    if (isDocumentOnlyClient(holding)) {
      return <DocumentOnlyTile client={holding} />
    }
    if (isFund(holding)) {
      return <FavouriteFundTile fund={holding} />;
    }
    return <AddAdditionalHoldings isTile />;
  };

  const BaseTableRow = ({ buttonText, icon, link, title, state, valuation, onClick }: {
    buttonText: string;
    icon?: React.ReactElement,
    link: string | { pathname: string, state: { clientId: string } };
    onClick?: (event: React.MouseEvent) => void;
    state?: string;
    title: string;
    valuation?: Valuation;
  }) => (
    <TableRow style={{ borderBottom: '1px solid #D9D9D6' }}>
      <TableCell>
        <Grid container alignItems="center" spacing={1}>
          {icon && (
            <Grid item style={{ display: 'flex' }}>
              {icon}
            </Grid>
          )}
          <Grid item>
            <Anchor to={link} onClick={onClick}>
              {title}
            </Anchor>
          </Grid>
        </Grid>
      </TableCell>

      {!valuation ? (
        <>
          <TableCell>-</TableCell>
          <TableCell align="right">-</TableCell>
        </>
      ) : state === States.Success ? (
        <>
          <TableCell>
            {valuation.currencyCode}
          </TableCell>
          <TableCell align="right">
            {formatNumber(
              valuation.value,
              valuation.currencyCode,
              2
            )}
          </TableCell>
          {/* <TableCell align="right">
              {valuation.date
                ? getFormattedDate(valuation.date)
                : '-'}
            </TableCell> */}
        </>
      ) : state === States.Error ? (
        <TableCell colSpan={2}>
          Error loading data
        </TableCell>
      ) : (
        <TableCell colSpan={2}>
          Loading...
        </TableCell>
      )}
      <TableCell align="right">
        <Button
          variant="outlined"
          color="primary"
          size="small"
          to={link}
          component={Link}
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            whiteSpace: 'nowrap',
          }}
        >
          {buttonText}
        </Button>
      </TableCell>
    </TableRow>
  );

  const GenericTableRow = ({ holding }: { holding: Holding }) => {
    if (isClient(holding)) {
      const { id, preferredName, state, valuation } = holding;
      return (
        <BaseTableRow
          link={`/accounts/${id}/accounts/`}
          title={preferredName}
          state={state}
          valuation={valuation}
          buttonText="View Accounts"
          onClick={(ev: React.MouseEvent) => {
            ev.preventDefault();
            setSelectedAccountId(id);
          }}
        />
      );
    }
    if (isDocumentOnlyClient(holding)) {
      const { id, preferredName } = holding;
      return (
        <BaseTableRow
          link={{ pathname: '/documents', state: { clientId: id } }}
          icon={<DarkDocumentOnlyClientIcon />}
          title={preferredName}
          buttonText="View Documents"
        />
      );
    }
    if (isFund(holding)) {
      const { fundCode, fundName } = holding;
      return (
        <BaseTableRow
          link={`/portfolio-holdings/${fundCode}`}
          title={fundName}
          icon={<StarIcon fontSize="small" />}
          buttonText="View Details"
        />
      );
    }
    return null;
  };

  // add a loading screen - this may never be seen
  if (loading) {
    return (
      <Banner>
        <Box>
          <Loading />
        </Box>
      </Banner>
    );
  }

  // feature detection & check to see if there are holdings to show
  if (!settings['ClientHoldings.Enabled'] || holdings.length === 0) {
    return (
      <>
        <Banner>
          <Box>
            <Quick />
          </Box>
        </Banner>

        <ThemeDark>
          <Box
            component={Paper}
            paddingY={8}
            style={{ backgroundColor: '#1C2939' }}
          >
            <Container>
              <AdditionalBoxes />
            </Container>
          </Box>
        </ThemeDark>
      </>
    );
  }

  // there are clients, so display them to the visitor
  return (
    <Box>
      {/* TODO: remove this */}

      <Fragment>
        <Banner>
          {clients.length === 0 ? (
            <Box>
              <Quick />
            </Box>
          ) : (
            <Box marginTop={4}>
              <Grid container spacing={3} direction="row" alignItems="flex-start">
                <Grid item xs={12} md={9}>
                  <Tile expanded client={getSelectedClient()} />
                </Grid>

                <Grid item xs={12} md={3}>
                  <Box
                    padding={2}
                    style={{ backgroundColor: 'rgba(0,0,0,.9)', color: '#fff' }}
                  >
                    <Typography variant="caption">
                      Valuations displayed are indicative. Audited valuations are
                      available in the periodic reporting provided. For further
                      details see information provided on the Account Details
                      page.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Banner>

        {/* if there are clients... */}

        <ThemeDark>
          <Box
            component={Paper}
            paddingY={8}
            style={{ backgroundColor: '#1C2939' }}
          >
            <Container>
              <Box marginBottom={8}>
                <Stack
                  spacing={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography>Holdings</Typography>
                    {/* thhere is no point in showing pagination data if there isn't many items to show */}
                    {holdings.length > PAGE_SIZE && (
                      <Typography>
                        {displayedHoldings.length > 1
                          ? `${
                            view === View.Tabular
                              ? `1 - ${Math.min(
                                tableRows,
                                displayedHoldings.length - 1
                              )}`
                              : `${Math.min(
                                page * PAGE_SIZE + 1,
                                displayedHoldings.length - 1
                              )} - ${Math.min(
                                (page + 1) * PAGE_SIZE,
                                displayedHoldings.length - 1
                              )}`
                          } of ${holdings.length}`
                          : 'No results'}
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <Stack
                      spacing={3}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {/*  */}
                      {view === View.Tabular && (
                        <Button
                          variant="text"
                          color="primary"
                          onClick={handleTileView}
                          startIcon={<GridOnIcon />}
                          style={{
                            fontSize: 'inherit',
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          Grid view
                        </Button>
                      )}

                      {view === View.Tile && (
                        <Button
                          variant="text"
                          color="primary"
                          onClick={handleTabularView}
                          startIcon={<TableChartIcon />}
                          style={{
                            fontSize: 'inherit',
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          Table view
                        </Button>
                      )}

                      {/* if there are a lot of items to show, implement the ability to search */}
                      {holdings.length > 0 && (
                        <Box>
                          <SearchInput
                            id="account-search"
                            onChange={handleSearch}
                          />
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </Stack>
              </Box>

              {/* toggle for tile view */}
              <Collapse in={view == View.Tile}>
                <ThemeDefault>
                  {displayedHoldings.length - 1 === 0 ? (
                    <Alert severity="info">
                      <AlertTitle>There are no holdings to show</AlertTitle>
                    </Alert>
                  ) : (
                    <>
                      {displayedHoldings.length > PAGE_SIZE ? (
                        // if the are multiple pages to view, implement a slider
                        <>
                          <Swiper
                            pagination={true}
                            onSwiper={(swiper) => {
                              if (!swiperInstance) {
                                swiperInstance = swiper;
                              }
                            }}
                            onSlideChange={(swiper) => {
                              setPage(swiper.activeIndex);
                            }}
                            spaceBetween={10}
                            slidesPerView={1}
                            allowTouchMove
                            className="theme-dark"
                            navigation={{
                              nextEl: '.review-swiper-button-next',
                              prevEl: '.review-swiper-button-prev',
                            }}
                          >
                            {Array(Math.ceil(displayedHoldings.length / PAGE_SIZE))
                              .fill(1)
                              .map((_, index) => (
                                <SwiperSlide key={`account-${index}`}>
                                  <Box marginBottom={6}>
                                    <Grid
                                      container
                                      spacing={3}
                                      direction="row"
                                    >
                                      {displayedHoldings
                                        .slice(
                                          index * PAGE_SIZE,
                                          index * PAGE_SIZE + PAGE_SIZE
                                        )
                                        .map((holding, clientIndex) => (
                                          <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={3}
                                            key={`account-${index}-${clientIndex}`}
                                          >
                                            <GenericTile holding={holding} />
                                          </Grid>
                                        ))}
                                    </Grid>
                                  </Box>
                                </SwiperSlide>
                              ))}
                          </Swiper>

                          <Box
                            textAlign={'right'}
                            style={{
                              marginTop: -30,
                              position: 'relative',
                              zIndex: 9,
                            }}
                          >
                            <IconButton
                              aria-label="Previous"
                              className="review-swiper-button-prev"
                              size="medium"
                            >
                              <ArrowLeftIcon fontSize="inherit" />
                            </IconButton>

                            <IconButton
                              aria-label="Next"
                              className="review-swiper-button-next"
                              size="medium"
                            >
                              <ArrowRightIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        </>
                      ) : (
                        // implement a standard grid
                        <Grid container spacing={3} direction="row">
                          {displayedHoldings.map((holding, index: number) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                key={`account-${index}`}
                              >
                                <GenericTile holding={holding} />
                              </Grid>
                            );
                          })}
                        </Grid>
                      )}
                    </>
                  )}
                </ThemeDefault>
              </Collapse>
              {/* toggle for table view */}
              <Collapse in={view == View.Tabular}>
                {displayedHoldings.length - 1 === 0 ? (
                  <Alert severity="info">
                    <AlertTitle>There are no holdings to show</AlertTitle>
                  </Alert>
                ) : (
                  <TableContainer>
                    <Table className="noborder">
                      <TableHead>
                        <TableRow
                          style={{
                            borderBottom: '1px solid #D9D9D6',
                          }}
                        >
                          <TableCell style={{ width: '50%' }}>
                            Account name
                          </TableCell>
                          <TableCell>Currency</TableCell>
                          <TableCell align="right">Valuation</TableCell>
                          {/* <TableCell align="right">Date</TableCell> */}
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {displayedHoldings.slice(0, tableRows).map((holding, i) => (
                          <GenericTableRow holding={holding} key={i} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                {tableRows < displayedHoldings.length - 1 && (
                  <Box marginY={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleLoadMore}
                      style={{ paddingLeft: 20, paddingRight: 20 }}
                    >
                      Load More
                    </Button>
                  </Box>
                )}

                <AdditionalBoxes />
              </Collapse>
            </Container>
          </Box>
        </ThemeDark>
      </Fragment>
    </Box>
  );
};
