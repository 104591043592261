// TODO: comment
import {
  LIST_REFRESH_INTERVAL,
  STATUS_FAILED,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from '@abrdn-latest/config';
import { Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { getMessagesPerPage, SERVER_ACTION_DELAY } from 'api/constants';
import {
  GetReceivedMessagesResponse,
  GetSentMessagesResponse,
} from 'api/types';
import { useSecurity } from 'authentication';
import { DataError, Loading, NoData } from 'components/no-data';
import { Progress } from 'components/ui';
import { RefreshIcon } from 'icons';
import { toast } from 'material-react-toastify';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDeletedMessageId, getUnreadCount, selectDeletedIds } from 'redux/reducers/messages';
import { delayFire } from 'utils';
import { MessageList } from '../';

interface Props {
  /**
   *
   */
  autoSelectMessage: boolean;
  /**
   *
   */
  doDelete: any;
  /**
   *
   */
  messageSelector: any;
  /**
   *
   */
  messageStatus: any;
  /**
   *
   */
  onMessageClick(id: string): void;
  /**
   *
   */
  onUpdate?(): void;
  /**
   *
   */
  refreshMessages: any;
  /**
   *
   */
  refreshUnread: boolean;
  /**
   *
   */
  refreshingMessage: string;
  /**
   *
   */
  selectedId: string;

  setShape: any;
  shape: ListingState;
}

export interface ListingState {
  page: number;
  rows: number;
}

export const ViewListing = ({
  messageSelector,
  messageStatus,
  doDelete,
  refreshUnread,
  refreshMessages,
  refreshingMessage,
  onMessageClick,
  selectedId,
  autoSelectMessage,
  onUpdate,
  shape,
  setShape,
}: Props) => {
  // messages state
  const messages: GetReceivedMessagesResponse | GetSentMessagesResponse =
    useSelector(messageSelector);
  // loading state
  const loadingState = useSelector(messageStatus);

  // deleted messages
  const deletedMessages = useSelector(selectDeletedIds);

  // dispatch
  const dispatch = useDispatch();
  const { authState } = useSecurity();

  /**
   * Batch delete handling
   * @param selected
   */
  const handleDelete = async (selected: Array<string>) => {
    // loop, and store the deleted ids - this will be used to help filter the table listing
    for (let counter: number = 0; counter < selected.length; counter++) {
      dispatch(addDeletedMessageId(selected[counter]));
    }

    // delere all the messages
    await doDelete(selected);

    // wait a few seconds, before trying to refresh the view
    setTimeout(() => {
      if (refreshUnread) {
        // refresh the unread counter
        dispatch(getUnreadCount());
      }
      // refresh the current list
      refreshView();
    }, SERVER_ACTION_DELAY * 3);
  };

  /**
   * Handle pagination
   * @param page
   * @param rows
   */
  const onPagination = (page: number, rows: number): void => {
    setShape({ page, rows });

    // refresh the list
    dispatch(refreshMessages({ start: page * rows, max: rows }));
  };

  /**
   * Refresh the list
   * @param isRefresh
   */
  const refreshView = (isRefresh: boolean = true) => {
    // cancel any interval
    if (window.refreshInterval) {
      clearInterval(window.refreshInterval);
    }

    // reload the message list
    dispatch(
      refreshMessages({
        start: shape.page * shape.rows,
        max: getMessagesPerPage(),
      })
    );

    // set up a refresh interval to check for new messages
    if (LIST_REFRESH_INTERVAL) {
      window.refreshInterval = setInterval(refreshView, LIST_REFRESH_INTERVAL);
    }

    // it this is a refresh call, and the document tab is focused, show a toast
    // doing this will help prevent multiple toasts in some browsers
    if (isRefresh && !document.hidden) {
      toast.dark(<Progress label={refreshingMessage} />, {
        closeButton: false,
        autoClose: 4000,
      });
    }
  };

  // when the view initially loads, request the messages
  useEffect(() => {
    if (authState.isAuthenticated) {
      delayFire(() => {
        refreshView(false);
      });
    }
  }, [authState.isAuthenticated]);

  useEffect(() => {
    if (
      messages &&
      messages.messages.length > 0 &&
      !selectedId &&
      autoSelectMessage
    ) {
      // @ts-ignore
      const firstNotDeletedMessage = messages.messages.find(m => !deletedMessages.includes(m.id));
      if (firstNotDeletedMessage?.id) {
        onMessageClick(firstNotDeletedMessage.id);
      }
    }

    if (onUpdate) {
      onUpdate();
    }
  }, [messages]);

  // when the view initially loads, request the messages
  useEffect(() => {
    // when the view is unloaded, perform a clean up
    return () => {
      if (window.refreshInterval) {
        clearInterval(window.refreshInterval);
      }
    };
  }, []);

  // @ts-ignore
  const notDeletedMessages = messages?.messages.filter(msg => !deletedMessages.includes(msg.id)) ?? [];
  const hasAnyMessages = notDeletedMessages.length > 0;

  return (
    <Fragment>
      {hasAnyMessages ? (
        <MessageList
          selectedId={selectedId}
          onDelete={handleDelete}
          data={messages}
          onPagination={onPagination}
          onMessageClick={onMessageClick}
        />
      ) : (
        <Paper>
          {loadingState === STATUS_FAILED && (
            <DataError>
              <Typography paragraph>
                Something went wrong. Please try again.
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  refreshView();
                }}
                endIcon={<RefreshIcon />}
              >
                Refresh
              </Button>
            </DataError>
          )}
          {loadingState === STATUS_LOADING && <Loading />}
          {loadingState === STATUS_SUCCESS && (
            <NoData>
              <Typography paragraph>No messages to view</Typography>
            </NoData>
          )}
        </Paper>
      )}
    </Fragment>
  );
};
