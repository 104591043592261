import {
  STATUS_DATA_LOADING,
  STATUS_FAILED,
  STATUS_INIT_ERROR,
  STATUS_SENDING,
  STATUS_SENT,
} from '@abrdn-latest/config';
import { useRouter } from '@abrdn-latest/use';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { getCountryList, getInvestorTypes, registerAccount } from 'api';
import { Anchor } from 'components/core';
import { ValidationProvider } from 'components/file-selector';
import { Form, Input, Select } from 'components/form';
import { Progress } from 'components/ui';
import { toast } from 'material-react-toastify';
import { TermsAndConditions } from 'pages/terms-and-conditions/PageTermsAndConditions';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as validation from 'validation';
import * as yup from 'yup';
import { PageAuth } from '../pages/authentication/PageAuth';
import { SuccessfullyRegisteredDialog } from './SuccessfullyRegisteredDialog';
import { ErrorDialog } from './ErrorDialog';
import { AlreadyRegisteredDialog } from './AlreadyRegisteredDialog';

const ALREADY_REGISTERED = 'already-registered';

const schema = yup.object().shape({
  register_email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required')
    .test('minLength', 'Minimum length is 5 characters', val => val!.length >= 5)
    .test('maxLength', 'Maximum length is 80 characters', val => val!.length <= 80),
  register_firstname: yup
    .mixed()
    .required('Firstname is required')
    .test('notblank', 'Firstname is required', (value) => {
      return String(value) !== '';
    })
    .test(
      'validCharacters',
      'Please enter valid characters only',
      validation.validCharacters
    )
    .test('length', 'Maximum length is 40 characters', val => val.length <= 40),
  register_lastname: yup
    .mixed()
    .required('Lastname is required')
    .test('notblank', 'Lastname is required', (value) => {
      return String(value) !== '';
    })
    .test(
      'validCharacters',
      'Please enter valid characters only',
      validation.validCharacters
    )
    .test('length', 'Maximum length is 50 characters', val => val.length <= 50),
  register_accepted: yup
    .boolean()
    .required('You must accept the terms and conditions')
    .oneOf([true], 'The terms and conditions must be accepted.'),
  register_country: yup.string().required('Country is a required field'),
  register_investortype: yup
    .string()
    .required('Investor Type is a required field'),
});

export function RegisterForm() {
  const { t } = useTranslation(['common']);

  const [formStatus, setFormStatus] = useState('');
  const [countryList, setCountryList] = useState<any[] | null>(null);
  const [investorTypes, setInvestorTypes] = useState<any[] | null>(null);
  const [termsOpen, setTermsOpen] = React.useState<boolean>(false);
  const [data, setData] = useState({
    register_email: '',
    register_firstname: '',
    register_lastname: '',
    register_accepted: false,
    register_country: '',
    register_investortype: '',
  });

  const router = useRouter();

  const methods = useForm({
    defaultValues: {
      register_email: data.register_email,
      register_firstname: data.register_firstname,
      register_lastname: data.register_lastname,
      register_accepted: data.register_accepted,
      register_country: data.register_country,
      register_investortype: data.register_investortype,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { control, register, handleSubmit, errors } = methods;

  useEffect(() => {
    async function runEffect() {
      setFormStatus(STATUS_DATA_LOADING);

      try {
        const response = await getCountryList();
        const types = await getInvestorTypes();

        if (response) {
          setCountryList(response);
        }

        if (types) {
          setInvestorTypes(types);
        }

        setFormStatus('');
      } catch (e) {
        setFormStatus(STATUS_INIT_ERROR);
      }
    }
    runEffect();
  }, []);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [formStatus, errors]);

  async function onSubmit(data: any) {
    setData(data);

    setFormStatus(STATUS_SENDING);

    const region = countryList?.find(
      item => item.code === data.register_country
    )?.regionCode ?? '';

    try {
      const res = await registerAccount(
        data.register_email,
        data.register_firstname,
        data.register_lastname,
        region,
        data.register_country,
        data.register_investortype,
        data.register_accepted
      );

      if (res.detail === 'ExistsOkta:ExistsConnect') {
        setFormStatus(ALREADY_REGISTERED);
      } else if (!res.errors || !Object.keys(res.errors).length) {
        toast.success(t('common:forms.register.messages.toast-success'));
        setFormStatus(STATUS_SENT);
      } else {
        setFormStatus(STATUS_FAILED);
      }
    } catch (e) {
      setFormStatus(STATUS_FAILED);
    }
  }

  return (
    <PageAuth>
      {(formStatus === '' || formStatus === STATUS_SENDING) && (
        <ValidationProvider schema={schema}>
            <FormProvider {...methods}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h4" component="h3">
                  {t('common:forms.register.title')}
                </Typography>

                <Typography paragraph>
                  abrdn Connect is a servicing tool for our Institutional clients, and
                  provides all users with information on our publicly available funds
                </Typography>

                <Input
                  ref={register}
                  id="register_email"
                  type="email"
                  label="Email"
                  name="register_email"
                  error={!!errors.register_email}
                  helperText={errors?.register_email?.message}
                />

                <Input
                  ref={register}
                  id="register_firstname"
                  type="text"
                  label="Firstname"
                  name="register_firstname"
                  error={!!errors.register_firstname}
                  helperText={errors?.register_firstname?.message}
                />

                <Input
                  ref={register}
                  id="register_lastname"
                  type="text"
                  label="Lastname"
                  name="register_lastname"
                  error={!!errors.register_lastname}
                  helperText={errors?.register_lastname?.message}
                />

                {countryList && countryList.length > 0 && (
                  <Select
                    ref={register}
                    control={control}
                    // @ts-ignore
                    id="register_country"
                    label="Country"
                    name="register_country"
                    options={countryList.map((item: any, index: number) => {
                      return {
                        displayText: item.displayText,
                        value: item.code,
                      };
                    })}
                    error={!!errors.register_country}
                    helperText={errors?.register_country?.message}
                  />
                )}

                {investorTypes && investorTypes.length > 0 && (
                  <Select
                    ref={register}
                    control={control}
                    // @ts-ignore
                    id="register_investortype"
                    label="Investor Type"
                    name="register_investortype"
                    options={investorTypes.map(
                      (item: any, index: number) => {
                        return {
                          displayText: item.displayText,
                          value: item.code,
                        };
                      }
                    )}
                    error={!!errors.register_investortype}
                    helperText={errors?.register_investortype?.message}
                  />
                )}

                <div>
                  <div>
                    <Controller
                      name="register_accepted"
                      control={control}
                      render={(props: any) => (
                        <Checkbox
                          color="primary"
                          {...props}
                          id="register_accepted"
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />

                    <label htmlFor="register_accepted">
                      I have read and accepted the{' '}
                      <Anchor
                        to="/terms-and-conditions"
                        onClick={() => setTermsOpen(true)}
                      >
                        Terms &amp; Conditions
                      </Anchor>
                    </label>
                  </div>
                  <FormHelperText
                    error={!!errors?.register_accepted?.message}
                  >
                    {errors?.register_accepted?.message}
                  </FormHelperText>
                </div>

                <Box paddingY={2}>
                  <Button
                    size="large"
                    disabled={formStatus === STATUS_SENDING}
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    Register {formStatus === STATUS_SENDING && <Progress />}
                  </Button>
                </Box>
              </Form>
            </FormProvider>
          </ValidationProvider>
      )}

      {formStatus === STATUS_SENT && <SuccessfullyRegisteredDialog />}

      {(formStatus === STATUS_INIT_ERROR || formStatus === STATUS_FAILED) && (
        <ErrorDialog onBack={() => router.push('/auth/landing')} />
      )}

      {formStatus === ALREADY_REGISTERED && <AlreadyRegisteredDialog />}

      <Dialog
        open={termsOpen}
        onClose={() => setTermsOpen(false)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" disableTypography>
          <Typography variant="h4" component="h2" style={{ margin: 0 }}>
            Terms &amp; Conditions
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <TermsAndConditions />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTermsOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </PageAuth>
  );
};
