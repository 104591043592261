// TODO: comment
import { useWidth } from '@abrdn-latest/use';
import { useSecurity } from 'authentication';
import React, { useEffect, useState } from 'react';
import { HeaderDesktop } from './HeaderDesktop';
import { HeaderMobile } from './HeaderMobile';

interface Props {
  alt: string;
  logo: string;
}

export type { Props as HeaderProps };

export const Header = (props: Props) => {
  const { isDesktop } = useWidth();

  const { authState } = useSecurity();

  const [authenticated, setAuthenticated] = useState(
    authState.isAuthenticated
  );

  useEffect(() => {
    setAuthenticated(authState.isAuthenticated);
  }, [authState.isAuthenticated]);

  if (authState.isPending) {
    return null;
  }

  if (!authenticated) {
    return null;
  }

  return isDesktop ? <HeaderDesktop /> : <HeaderMobile {...props} />;
};
