// TODO: comment
import {
  STATUS_FAILED,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from '@abrdn-latest/config';
import { useDidMountEffect } from '@abrdn-latest/use';
import {
  getCountryValue,
  getFilters,
  getFundList,
  getInvestorTypeValue,
} from 'api';
import { AllFilters, FundlistingProps } from 'api/types';
import { useSecurity } from 'authentication';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, selectUserDetails } from 'redux/reducers/account';
import { SearchFilters } from '../components';
import { PortfolioHoldingsProvider } from './';

interface Props {
  children: React.ReactNode;
}

export const PortfolioHoldings = ({ children }: Props) => {
  const { authState } = useSecurity();

  // store the state of the filters applied by the user
  const [state, setState] = useState<SearchFilters>({
    country: '',
    countryDisplay: '',
    investorType: '',
    investorTypeDisplay: '',
    search: '',
    assetFundClass: [],
    fundRange: [],
    productType: [],
  });

  // filters that the user can apply
  const [status, setStatus] = useState<string>('');

  // filters that the user can apply
  const [filters, setFilters] = useState<AllFilters | null>(null);

  //
  const dispatch = useDispatch();
  const details = useSelector(selectUserDetails);

  useEffect(() => {
    const load = async () => {
      const countryDisplay = await getCountryValue(details.country);
      const investorTypeDisplay = await getInvestorTypeValue(
        details.investorType
      );

      setState({
        ...state,
        ...{
          country: details.country,
          countryDisplay,
          investorType: details.investorType,
          investorTypeDisplay,
        },
      });
    };

    load();
  }, [details]);

  /**
   *
   * @param country
   */
  const setCountry = async (country: string) => {
    const countryDisplay = await getCountryValue(country);

    setState({ ...state, ...{ country, countryDisplay } });
  };

  /**
   *
   * @param investorType
   */
  const setInvestorType = async (investorType: string) => {
    const investorTypeDisplay = await getInvestorTypeValue(investorType);

    setState({ ...state, ...{ investorType, investorTypeDisplay } });
  };

  /**
   *
   * @param country
   * @param investorType
   */
  const setViewAs = async (country: string, investorType: string) => {
    const countryDisplay = await getCountryValue(country);
    const investorTypeDisplay = await getInvestorTypeValue(investorType);

    setState({
      ...state,
      ...{ country, investorType, countryDisplay, investorTypeDisplay },
    });
  };

  /**
   *
   * @param search
   */
  const setSearch = (search: string) => {
    setState({ ...state, ...{ search } });
  };

  /**
   *
   * @param assetFundClass
   */
  const setAssetFunds = (assetFundClass: string[]) => {
    setState({ ...state, ...{ assetFundClass } });
  };

  /**
   *
   * @param fundRange
   */
  const setFundRanges = (fundRange: string[]) => {
    setState({ ...state, ...{ fundRange } });
  };

  /**
   *
   * @param productType
   */
  const setProductTypes = (productType: string[]) => {
    setState({ ...state, ...{ productType } });
  };

  /**
   *
   */
  const clearFilters = () => {
    setState({
      ...state,
      ...{ productType: [], assetFundClass: [], fundRange: [] },
    });
  };

  // when the component changes, load he user details so we can get the unvestor type and country
  useEffect(() => {
    if (authState.isAuthenticated) {
      dispatch(getUserDetails());
    }
  }, [authState.isAuthenticated]);

  // when the state changes we need to save the cookie of the current state.
  useDidMountEffect(() => {
    const load = async () => {
      if (
        authState.isAuthenticated &&
        state.country &&
        state.investorType &&
        status !== STATUS_LOADING
      ) {
        if (status !== STATUS_LOADING) {
          setStatus(STATUS_LOADING);
        }

        try {
          //
          await loadFilters();

          const funds = await getFundList(
            undefined,
            state.search || undefined,
            state.fundRange,
            state.productType,
            state.assetFundClass,
            state.investorType,
            state.country
          );

          // if the funds was loaded successfully, make them available
          if (funds) {
            setFunds(funds);
          }

          setStatus(STATUS_SUCCESS);
        } catch (e) {
          setStatus(STATUS_FAILED);
        }
      }
    };

    load();
  }, [state, authState.isAuthenticated]);

  /**
   *
   * @returns
   */
  const loadFilters = async (): Promise<boolean> => {
    try {
      // get the filters
      const filters = await getFilters(state.investorType, state.country);

      // if the request was successful
      if (filters) {
        setFilters(filters);
      }
    } catch (e) {
      //
    }

    return true;
  };

  const [funds, setFunds] = useState<FundlistingProps | null>(null);

  const value = {
    status,
    state,
    funds,
    filters,
    setViewAs,
    setCountry,
    setInvestorType,
    setSearch,
    setAssetFunds,
    setFundRanges,
    setProductTypes,
    clearFilters,
  };

  // wrapping component
  return (
    <PortfolioHoldingsProvider value={value}>
      {children}
    </PortfolioHoldingsProvider>
  );
};
