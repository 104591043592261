// TODO: comment
import { Box } from '@material-ui/core';
import { useSecurity } from 'authentication';
import { PopupApp } from 'components/popup-app';
import 'material-react-toastify/dist/ReactToastify.css';
import {
  useMessageComposerDispatch,
  useMessageComposerState,
} from 'utils/message-context';
import { NewMessageForm } from '../';
import './MessagePopup.scss';

export const MessagePopup = () => {
  const { state, props = {} } = useMessageComposerState();
  const dispatch = useMessageComposerDispatch();

  const { authState } = useSecurity();

  if (!authState.isAuthenticated || state === 'closed') {
    return null;
  }

  return (
    <PopupApp
      state={state}
      showUIMinimise
      title={'New message'}
      onClose={() => {
        // @ts-ignore
        dispatch({ type: 'closed' });
      }}
      onStateChange={(state) => {
        // @ts-ignore
        dispatch({ type: state, props });
      }}
    >
      <Box maxWidth={500} marginBottom={2}>
        <NewMessageForm {...props} />
      </Box>
    </PopupApp>
  );
};
