// TODO: comment
import { useWidth } from '@abrdn-latest/use';
import { Box, Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { InfoPanel } from './InfoPanel';
import { useHeight } from '../../@abrdn-latest/use/useHeight';
import { getPublicPath } from '../../utils/assets';

const useStyles = makeStyles<{}, { height: number, isSmall: boolean }>({
  widgetBox: {
    border: '1px solid #D9D9D6',
  },
  content: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    backgroundImage: `url("${getPublicPath('images/backgrounds/authentication.svg')}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  page: {
    display: 'flex',
    flexGrow: 1,
    overflowY: 'hidden',
    flexDirection: props => props.isSmall ? 'column' : 'row',
  },
  container: {
    display: props => props.height > 580 ? 'flex' : 'block',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
    paddingTop: props => props.isSmall ? 48 : 0,
    paddingBottom: props => props.isSmall ? 48 : 0,
    height: props => props.isSmall ? '' : '100vh',
    background: 'transparent',
    overflowY: props => props.isSmall ? 'initial' : 'scroll',
  },
  widgetContainer: {
    minHeight: props => props.height > 580 ? 750 : 0,
    marginTop: props => props.height > 580 ? 150 : 0,
  },
});

export const PageAuth = ({ children }: PropsWithChildren<{}>) => {
  const { isSmall } = useWidth();
  const height = useHeight();

  const classes = useStyles({ isSmall, height });

  return (
    <Fragment>
      <Helmet>
        <title>Authentication</title>

        <style type="text/css">{`
        .main{
            display: flex;
   flex-direction: column;
        }
    `}</style>
      </Helmet>
      <Paper className={classes.page}>
        {!isSmall && <InfoPanel />}
        <Paper className={classes.content}>
          <Box className={classes.container}>
            <Container maxWidth="sm" className={!isSmall ? classes.widgetContainer : ''}>
              <Box className={classes.widgetBox}>
                <Paper>
                  <Box padding={4}>
                    {children}
                  </Box>
                </Paper>
              </Box>
            </Container>
          </Box>
        </Paper>
        {isSmall && <InfoPanel />}
      </Paper>
    </Fragment>
  );
};
