// TODO: comment
import { Banner } from '@abrdn/banner';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { IFrame } from 'components/iframe';
import { Breadcrumbs } from 'components/navigation';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { TilePlaceholder } from './';
import { getPublicPath } from '../../utils/assets';

const PageOurThinking = () => {
  const { t } = useTranslation(['common']);

  if (process.env.REACT_APP_FEATURE_OUR_THINKING) {
    return (
      <Fragment>
        <Helmet>
          <title>{t('common:titles.our-thinking')}</title>
        </Helmet>

        <Breadcrumbs
          trail={[{ label: 'Home', href: '/' }, { label: 'Our Thinking' }]}
        />

        <Box paddingY={6}>
          <Container>
            <Box paddingBottom={4}>
              <Typography variant="h3" component="h1">
                Our Thinking
              </Typography>
            </Box>

            <Grid container spacing={3} alignItems="stretch" wrap="wrap">
              <Grid item xs={12} sm={6} md={4}>
                <TilePlaceholder />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TilePlaceholder />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TilePlaceholder />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TilePlaceholder />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TilePlaceholder />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TilePlaceholder />
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box paddingY={6}>
          <Container>
            <IFrame src="https://www.abrdn.com/en-gb/connect" />
          </Container>
        </Box>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>{t('common:titles.our-thinking')}</title>
      </Helmet>

      <Banner imagePath={getPublicPath('images/backgrounds/our-thinking.jpg')}>
        <Typography variant="h3" component="h1">
          {t('common:titles.our-thinking')}
        </Typography>
      </Banner>
      <Box paddingY={6}>
        <Container>
          <IFrame src="https://www.abrdn.com/en-gb/connect" />
        </Container>
      </Box>
    </Fragment>
  );
};

export default PageOurThinking;
