import { Banner } from '@abrdn';
import { Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  ClimateInsights,
  EthicalSocialGovernance,
  SustainableInvesting,
} from './components';
import { getPublicPath } from '../../utils/assets';

const PageESG = () => {
  // language translations
  const { t } = useTranslation(['common']);

  return (
    <Fragment>
      <Helmet>
        <title>{t('common:titles.esg')}</title>
      </Helmet>

      <Banner imagePath={getPublicPath('images/backgrounds/esg.jpg')}>
        <Typography variant="h3" component="h1">
          {t('common:titles.esg')}
        </Typography>
      </Banner>

      {/*  */}
      <SustainableInvesting />

      {/*  */}
      <ClimateInsights />

      {/* ESG Banner and articles */}
      <EthicalSocialGovernance />
    </Fragment>
  );
};

export default PageESG;
