import './PageMessages.scss';

import {
  ActionOverridesType,
  MessageContextType,
  MessageProvider,
} from './MessageContext';
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { Fragment, useState } from 'react';
import { InboxIcon, PriorityHighIcon, SendIcon } from 'icons';
import {
  IndividualMessage,
  ListingState,
  MessageHeader,
  ViewListing,
} from './';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  deleteReceivedMessage,
  deleteReceivedMessages,
  deleteSentMessage,
  deleteSentMessages,
  getActionRequiredMessage,
  getReceivedMessage,
  getSentMessage,
  setMessageAsRead,
} from 'api';
import {
  getActionRequiredMessages,
  getReceivedMessages,
  getSentMessages,
  selectActionRequiredMessages,
  selectActionRequiredStatus,
  selectReceivedMessages,
  selectReceivedStatus,
  selectSentMessages,
  selectSentStatus,
} from 'redux/reducers/messages';

import { Banner } from '@abrdn';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import React from 'react';
import { ThemeDark } from 'styles';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWidth } from '@abrdn-latest/use';
import { getPublicPath } from '../../utils/assets';

type Mailbox = 'inbox' | 'action-required' | 'sent';

export interface MessagePageParams {
  mailbox?: Mailbox;
  messageId?: string;
}

let instance: any;

const PageMessages = (props: any) => {
  const { t } = useTranslation(['common']);

  const { isMobile } = useWidth();

  // get the possible routes
  const params: MessagePageParams = useParams();

  const [mailbox, setMailbox] = useState<Mailbox>(params.mailbox || 'inbox');

  const [messageId, setMessageId] = useState<string>(params.messageId || '');

  const changeMailbox = (type: Mailbox): void => {
    setMailbox(type);
    setMessageId('');

    if (!isMobile) {
      props.history.replace({ pathname: `/messages/${type}` });
    }
  };

  const changeMessage = (id: string): void => {
    setMessageId(id);

    if (!isMobile) {
      if (id) {
        props.history.replace({ pathname: `/messages/${mailbox}/${id}` });
      } else {
        props.history.replace({ pathname: `/messages/${mailbox}` });
      }
    } else {
      // TODO: slide
      if (instance) {
        instance.slideTo(1);
      }
    }
  };

  // used for mobile view
  const handleBack = () => {
    if (instance) {
      instance.slideTo(0);
    }
  };

  const onUpdate = () => {
    if (isMobile && instance) {
      setTimeout(() => {
        instance.update();
      }, 10);
    }
  };

  const [sentState, setSentState] = useState<ListingState>({
    page: 0,
    rows: 10,
  });

  const [inboxState, setInboxState] = useState<ListingState>({
    page: 0,
    rows: 10,
  });

  const [actionState, setActionState] = useState<ListingState>({
    page: 0,
    rows: 10,
  });

  const [actionOverrides, setActionOverrides] =
    React.useState<ActionOverridesType>({});

  const setOverride = (messageId: string, state: boolean): void => {
    setActionOverrides({ ...actionOverrides, ...{ [messageId]: state } });
  };

  const value: MessageContextType = {
    actionOverrides,
    setOverride,
  };

  const Primary = () => {
    return (
      <Fragment>
        <ThemeDark>
          <Paper>
            <Grid container>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  size="large"
                  onClick={() => {
                    changeMailbox('inbox');
                  }}
                  className={clsx('messages-button', {
                    active: mailbox === 'inbox',
                  })}
                  startIcon={<InboxIcon />}
                >
                  Inbox
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  size="large"
                  onClick={() => {
                    changeMailbox('action-required');
                  }}
                  className={clsx('messages-button', {
                    active: mailbox === 'action-required',
                  })}
                  startIcon={<PriorityHighIcon />}
                >
                  Actions
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  size="large"
                  onClick={() => {
                    changeMailbox('sent');
                  }}
                  className={clsx('messages-button', {
                    active: mailbox === 'sent',
                  })}
                  startIcon={<SendIcon />}
                >
                  Sent
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </ThemeDark>
        <Paper>
          {mailbox === 'sent' && (
            <ViewListing
              selectedId={messageId}
              messageSelector={selectSentMessages}
              messageStatus={selectSentStatus}
              doDelete={deleteSentMessages}
              refreshUnread={false}
              refreshMessages={getSentMessages}
              refreshingMessage="Refreshing Sent"
              autoSelectMessage={!isMobile}
              onMessageClick={changeMessage}
              onUpdate={onUpdate}
              shape={sentState}
              setShape={setSentState}
            />
          )}

          {mailbox === 'inbox' && (
            <ViewListing
              selectedId={messageId}
              messageSelector={selectReceivedMessages}
              messageStatus={selectReceivedStatus}
              doDelete={deleteReceivedMessages}
              refreshUnread
              refreshMessages={getReceivedMessages}
              refreshingMessage="Refreshing Inbox"
              autoSelectMessage={!isMobile}
              onMessageClick={changeMessage}
              onUpdate={onUpdate}
              shape={inboxState}
              setShape={setInboxState}
            />
          )}

          {mailbox === 'action-required' && (
            <ViewListing
              selectedId={messageId}
              messageSelector={selectActionRequiredMessages}
              messageStatus={selectActionRequiredStatus}
              doDelete={deleteReceivedMessages}
              refreshUnread
              refreshMessages={getActionRequiredMessages}
              refreshingMessage="Refreshing Action Required"
              autoSelectMessage={!isMobile}
              onMessageClick={changeMessage}
              onUpdate={onUpdate}
              shape={actionState}
              setShape={setActionState}
            />
          )}
        </Paper>
      </Fragment>
    );
  };

  const Secondary = () => {
    return (
      <Box className="sticky-column">
        {mailbox === 'inbox' && (
          <IndividualMessage
            canReply
            messageId={messageId}
            getAllMessages={selectReceivedMessages}
            getMessage={getReceivedMessage}
            deleteMessage={deleteReceivedMessage}
            markAsRead={setMessageAsRead}
            goBack={isMobile ? handleBack : undefined}
            onUpdate={onUpdate}
            onDelete={changeMessage}
          />
        )}

        {mailbox === 'action-required' && (
          <IndividualMessage
            canReply
            messageId={messageId}
            getAllMessages={selectActionRequiredMessages}
            getMessage={getActionRequiredMessage}
            deleteMessage={deleteReceivedMessage}
            markAsRead={setMessageAsRead}
            goBack={isMobile ? handleBack : undefined}
            onUpdate={onUpdate}
          />
        )}

        {mailbox === 'sent' && (
          <IndividualMessage
            messageId={messageId}
            getAllMessages={selectSentMessages}
            getMessage={getSentMessage}
            deleteMessage={deleteSentMessage}
            goBack={isMobile ? handleBack : undefined}
            onUpdate={onUpdate}
            onDelete={changeMessage}
          />
        )}
      </Box>
    );
  };

  return (
    <Fragment>
      <Helmet>
        <title>{t('common:titles.new-message')}</title>
      </Helmet>

      <Banner imagePath={getPublicPath('images/backgrounds/messages.jpg')}>
        <Typography variant="h3" component="h1">
          {t('common:titles.messages')}
        </Typography>
        <MessageHeader />
      </Banner>
      <MessageProvider value={value}>
        <Box paddingY={isMobile ? 0 : 6} style={{ backgroundColor: '#eee' }}>
          <Container disableGutters={isMobile}>
            {isMobile ? (
              <Paper>
                <Swiper
                  onSwiper={(swiper) => {
                    if (!instance) {
                      instance = swiper;
                    }
                  }}
                  autoHeight
                  spaceBetween={0}
                  slidesPerView={1}
                >
                  <SwiperSlide>
                    <Primary />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Secondary />
                  </SwiperSlide>
                </Swiper>
              </Paper>
            ) : (
              <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} sm={4}>
                  <Primary />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Secondary />
                </Grid>
              </Grid>
            )}
          </Container>
        </Box>
      </MessageProvider>
    </Fragment>
  );
};

export default PageMessages;
