import { PropsWithChildren, useEffect, useState } from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { getOktaCredentials } from '../api/auth/oktaCredentials';
import { Splash } from '../components/splash';

let oktaAuth: OktaAuth;

export function OktaSecurity({ children, brand }: PropsWithChildren<{ brand: any }>) {
  const [oktaLoaded, setOktaLoaded] = useState(false);

  useEffect(() => {
    async function setOktaAuth() {
      if (!oktaAuth) {
        const oktaCredentials = await getOktaCredentials();
        oktaAuth = new OktaAuth(oktaCredentials);
        setOktaLoaded(true);
      }
    }
    setOktaAuth();
  }, []);

  if (!oktaLoaded || !oktaAuth) {
    return <Splash {...brand} />
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={() => {}}>
      {children}
    </Security>
  );
}
