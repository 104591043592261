// TODO: comment
import { isIE } from '@abrdn/utils';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DocumentUploadRestrictionsResponse } from 'api/types';
import { useSecurity } from 'authentication';
import { Input } from 'components/form';
import { DeleteIcon, DocumentUploadIcon } from 'icons';
import React, { createRef, useEffect, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDocumentRestrictions,
  selectDocumentRestrictions,
} from 'redux/reducers/documents';
import { delayFire } from 'utils';
import { Restrictions } from './Restrictions';

interface FileSelectorProps {
  maxRows?: number;
  minRows?: number;
}

// TODO: move this to scss
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  htmllabel: {
    display: 'inline-block',
    wordBreak: 'break-all',
  },
  label: {
    marginTop: 8,
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    border: '1px solid rgba(0, 0, 0, 0.5)',
    padding: '5px 15px',
    borderRadius: 30,
    wordBreak: 'break-all',
  },
}));

export const FileSelector = ({
  minRows = 0,
  maxRows = 10,
}: FileSelectorProps) => {
  const IS_IE: boolean = isIE();

  const { register, errors, control, watch } = useFormContext(); // retrieve all hook methods
  const classes = useStyles();
  const PLEASE_SELECT: string = 'Select a file';
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'files',
  });

  const watchFieldArray = watch('files');

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const dispatch = useDispatch();
  const { authState } = useSecurity();

  // get the document restrictions
  const restrictions: DocumentUploadRestrictionsResponse = useSelector(
    selectDocumentRestrictions
  );

  useEffect(() => {
    if (authState.isAuthenticated) {
      delayFire(() => {
        // TODO: change this to work on status
        if (restrictions.fileSizeLimitBytes === 0) {
          dispatch(getDocumentRestrictions());
        }
      });
    }
  }, [authState.isAuthenticated]);

  useEffect(() => {
    for (let i = 0; i < minRows; i++) {
      append({ description: '', file: '' });
    }

    selectLastInput();
  }, []);

  const selectLastInput = () => {
    setTimeout(() => {
      if (selectorRef && selectorRef.current) {
        // @ts-ignore
        const e = selectorRef.current.querySelectorAll('input[type="file"]');
        if (e) {
          const lastInput = [].slice.call(e).pop();
          if (lastInput) {
          }
        }
      }
    }, 20);
  };

  const selectorRef = useRef(null);

  return (
    <div ref={selectorRef}>
      {/* field restrictions message */}
      {controlledFields.length > 0 && (
        <Restrictions restrictions={restrictions} />
      )}

      {/* loop through the fields */}
      {controlledFields.map((field, index) => {
        const theErrors =
          errors && errors.files && errors.files.length
            ? errors.files[index]
            : {};

        const nameRef = createRef<HTMLButtonElement>();

        const descError = theErrors?.description?.message;
        const fileError = theErrors?.file?.message;

        const errorMessages: string[] = [];

        if (descError !== undefined) {
          errorMessages.push(descError);
        }

        if (fileError !== undefined) {
          errorMessages.push(fileError);
        }

        return (
          <Box key={field.id}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justify="space-between"
              wrap="nowrap"
            >
              <Grid item xs={6} md={6}>
                <Input
                  key={`files.${field.id}.desc`}
                  ref={register()}
                  id={`files.${index}.description`}
                  type="text"
                  label="Description"
                  name={`files[${index}].description`}
                  control={control}
                  defaultValue={field.description}
                  error={theErrors ? !!theErrors.description : false}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Box className={classes.root}>
                  <input
                    key={`files.${field.id}.file`}
                    className={classes.input}
                    type="file"
                    id={`file.${field.id}`}
                    name={`files[${index}].file`}
                    defaultValue=""
                    accept={restrictions.allowedFileExtensions
                      .map((item: string) => {
                        return `.${item}`;
                      })
                      .join(',')}
                    ref={register()}
                    onChange={(ev: any) => {
                      try {
                        const file = ev.target.files[0];
                        if (file) {
                          if (nameRef && nameRef.current) {
                            // @ts-ignore
                            const fileName = String(file.name);
                            const trimLength: number = 40;

                            nameRef.current.innerHTML = `${fileName.substr(
                              0,
                              trimLength
                            )}${fileName.length > trimLength ? '...' : ''}`;
                          }
                        } else {
                          if (nameRef && nameRef.current) {
                            // @ts-ignore
                            nameRef.current.innerHTML = PLEASE_SELECT;
                          }
                        }
                      } catch (e) {}
                    }}
                  />
                  {IS_IE ? (
                    <label
                      htmlFor={`file.${field.id}`}
                      className={classes.label}
                    >
                      <span color="primary" ref={nameRef}>
                        {PLEASE_SELECT}
                      </span>
                    </label>
                  ) : (
                    <label
                      className={classes.htmllabel}
                      htmlFor={`file.${field.id}`}
                    >
                      <Button
                        color="primary"
                        component="span"
                        variant="outlined"
                        ref={nameRef}
                        className={classes.label}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {PLEASE_SELECT}
                      </Button>
                    </label>
                  )}
                </Box>
              </Grid>

              {minRows !== maxRows && (
                <Grid item>
                  <Box>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Grid>
              )}
            </Grid>

            {errorMessages.length > 0 && (
              <FormHelperText error={true}>
                {errorMessages.join(' & ')}
              </FormHelperText>
            )}
          </Box>
        );
      })}

      {/* if the number of fields is less than the max rows, allow the user to add more fields  */}
      {controlledFields.length < maxRows && (
        <Box paddingY={2}>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={() => {
              append({ description: '', file: '' });
              selectLastInput();
            }}
            type="button"
            startIcon={<DocumentUploadIcon />}
          >
            {controlledFields.length > 0 ? 'Add another file' : 'Add a file'}
          </Button>
        </Box>
      )}
    </div>
  );
};
