// TODO: comment
import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    border: 'none',
    width: '100%',
    minWidth: '100%',
    height: '650px'
  },
  inner: {},
}));

export interface IFrameProps {
  src: string;
}

export const IFrame: FunctionComponent<IFrameProps> = ({ src }) => {
  const classes = useStyles();

  return (
    <Box className={classes.inner} dangerouslySetInnerHTML={{
      __html: `
          <iframe
            class="${classes.root}"
            allow="fullscreen"
            src="${src}"
            allowfullscreen
          />
        `
    }} />
  );
};
