import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { KeyboardArrowLeftIcon } from '../icons';
import { makeStyles } from '@material-ui/core/styles';
import { Anchor } from '../components/core';

const useStyles = makeStyles((theme) => ({
  backIcon: {
    marginRight: 4,
    width: 24,
    height: 24,
  },
}));

export function SuccessfullyRegisteredDialog() {
  const styles = useStyles();
  return (
    <>
      <Box mb={10}>
        <Typography variant="h4" component="h3">
          Thank you for registering for
          <br/>
          abrdn Connect
        </Typography>

        <Typography paragraph variant="body2">
          We have sent a verification link to your registered email address that
          will let you activate your account and set up your password.
        </Typography>

        <Typography paragraph variant="body2">
          <b>The link provided will expire after 60 minutes.</b>
        </Typography>

        <Typography paragraph variant="body2">
          If you do not receive an email or have been unable to activate your
          account in this time, please contact <b>ConnectHelp@abrdn.com</b>.
        </Typography>
      </Box>
      <Anchor to="/auth/landing">
        <KeyboardArrowLeftIcon className={styles.backIcon} />
        <b>Back to login</b>
      </Anchor>
    </>
  );
}
