// TODO: comment
import { Link as MaterialLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExternalLink } from 'icons';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  anchor: {
    display: 'inline-flex',
    alignItems: 'center',
    color: 'inherit',
  },
  link: {
    textDecoration: 'underline',
    //border: '1px solid red',
    display: 'inline-flex',
    alignItems: 'center',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  linkButton: {
    textDecoration: 'underline',
    //border: '1px solid green',
    display: 'inline-flex',
    alignItems: 'center',
    background: 'transparent',
    padding: 0,
    verticalAlign: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },

    '&[disabled]': {
      cursor: 'default',
      opacity: 0.6,
      textDecoration: 'none',
    },
  },
}));

export const Anchor = (props: any) => {
  const classes = useStyles();

  const { to } = props;

  if (to) {
    return (
      <MaterialLink
        component={RouterLink}
        color="primary"
        className={classes.link}
        {...props}
      />
    );
  }

  return (
    <MaterialLink
      component="button"
      color="primary"
      className={classes.linkButton}
      {...props}
    />
  );
};

interface ExternalProps {
  icon?: boolean;
  label: string;

  to: string;
}

export const ExternalAnchor = ({ to, label, icon = true }: ExternalProps) => {
  const classes = useStyles();

  return (
    <MaterialLink href={to} className={classes.link} target="_blank">
      {icon && <ExternalLink style={{ marginRight: 5 }} />} {label}
    </MaterialLink>
  );
};
