// TODO: comment
import { createContext, useContext } from 'react';
import { SalesforceAuthActions } from '../api/auth/types';

interface AuthState {
  authMessage?: string | null;
  error?: boolean;
  isAuthenticated?: boolean;
  isPending?: boolean;
  salesforceAuth?: SalesforceAuthActions;
  salesforceUrl?: string;
  screens?: any;
}
interface Context {
  authMethods: any;
  authState: AuthState;
  settings: any | null;
}

const SecurityContext = createContext<Context>({
  authState: {},
  authMethods: {},
  settings: null,
});

export const useSecurity = (): Context => useContext(SecurityContext);

export const SecurityProvider = SecurityContext.Provider;
