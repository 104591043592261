import { Box, Link as MaterialLink, Typography } from '@material-ui/core';
import { Anchor } from '../components/core';
import React from 'react';
import { KeyboardArrowLeftIcon } from '../icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backIcon: {
    marginRight: 4,
    width: 24,
    height: 24,
  },
}));

export function AlreadyRegisteredDialog() {
  const styles = useStyles();
  return (
    <>
      <Box mb={10}>
        <Typography variant="h4" component="h3">
          It looks like you’re already registered
        </Typography>

        <Typography paragraph variant="body2">
          There is already an active account set up using the email address you provided.
        </Typography>

        <Typography paragraph variant="body2">
          <b>Please return to the login page and sign in.</b>
        </Typography>

        <Typography paragraph variant="body2">
          If you have forgotten your password, you can reset it {' '}
          <MaterialLink href="https://dev-secure.abrdn.com/signin/forgot-password">
            <b>here</b>
          </MaterialLink>
          .
        </Typography>
      </Box>
      <Anchor to="/auth/landing">
        <KeyboardArrowLeftIcon className={styles.backIcon} />
        <b>Back to login</b>
      </Anchor>
    </>
  );
}
