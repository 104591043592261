import { useRouter } from '@abrdn-latest/use';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import { useSecurity } from 'authentication';
import React, { CSSProperties, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { Stack } from '../../@abrdn-latest/core';
import { Anchor } from '../../components/core';
import { useOktaAuth } from '@okta/okta-react';
import { getPublicPath } from '../../utils/assets';
import { ErrorDialog } from '../../authentication/ErrorDialog';
import { PageAuth } from './PageAuth';

interface FeatureTileProps {
  icon: 'portfolio' | 'holdings' | 'tasks' | 'reporting';
  text: string;
  title: string;
}

const useTileStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 260,
  },
  icon: {
    marginBottom: 32,
    position: 'relative',
    overflow: 'hidden',
    width: 80,
    height: 80,
    backgroundImage: (props: FeatureTileProps) =>
      `url("${getPublicPath(`images/icons/feature-${props.icon}.svg`)}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  title: {
    marginBottom: 20,
    fontSize: 20,
    fontWeight: 600,
  },
  text: {
    color: '#555555',
    fontSize: 14,
    fontWeight: 500,
  },
}));

function FeatureTile(props: FeatureTileProps) {
  const classes = useTileStyles(props);
  return (
    <Box className={classes.container}>
      <Box className={classes.icon} />
      <Typography variant="h5" className={classes.title}>
        {props.title}
      </Typography>
      <Typography className={classes.text}>
        {props.text}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => {
  const button: CSSProperties = {
    width: 135,
    fontSize: 14,
    fontWeight: 600,
  };
  return {
    paper: {
      background: 'transparent',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    container: {
      padding: '20px 28px',
    },
    banner: {
      position: 'relative',
      background: 'no-repeat 50% 0',
      backgroundImage: `url(${getPublicPath('images/backgrounds/icebreaker.png')})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center bottom',
      height: 800,
      padding: '24px 32px',
    },
    logo: {
      position: 'relative',
      overflow: 'hidden',
      width: 160,
      height: 50,
      margin: '-8px -26px',
      backgroundImage: `url("${getPublicPath('images/abrdn-black.png')}")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    titleBox: {
      paddingTop: 80,
      '@media (min-width:960px)': {
        paddingLeft: 112,
      },
    },
    title: {
      marginBottom: 16,
    },
    loginButton: {
      ...button,
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #00205B',
      '&:hover': {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), #00205B !important',
      },
    },
    registerButton: {
      ...button,
      color: '#00205B',
      background: '#D1D5DB',
      border: '2px solid #00205B',
      '&:hover': {
        background: '#DDD !important',
      },
    },
    personalInvestor: {
      position: 'absolute',
      right: 28,
      left: 28,
      bottom: -76,
      height: 152,
      width: 'calc(100% - 28px - 28px)',
      padding: 24,
      background: '#41273B',
      color: '#fff',
      fontSize: 14,
      '@media (min-width:600px)': {
        right: '10%',
        left: 'initial',
        maxWidth: 471,
        padding: '24px 64px 24px 24px',
      }
    },
    personalInvestorTitle: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
    },
    personalInvestorLink: {
      display: 'block',
      color: '#fff',
      fontSize: 14,
      fontWeight: 600,
      marginTop: 12,
    },
    personalInvestorImg: {
      position: 'relative',
      overflow: 'hidden',
      width: 64,
      height: 64,
      backgroundImage: `url("${getPublicPath('images/icons/personal-investor.svg')}")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    bottomContainer: {
      padding: '0 32px',
      '@media (min-width:960px)': {
        padding: '0 144px',
      },
    },
    createdForYou: {
      maxWidth: 600,
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    tilesContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
      gap: 40,
      '@media (min-width:600px)': {
        gap: 20,
      },
      '@media (min-width:960px)': {
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
      },
    },
    footer: {
      background: '#000000',
      color: '#FFFFFF',
      padding: '32px 32px',
      '@media (min-width:960px)': {
        padding: '32px 144px',
      },
    },
    allRightsReserved: {
      marginBottom: 8,
    },
    risk: {
      maxWidth: 870,
      fontSize: 14,
      lineHeight: '24px',
    },
  };
});

export function AuthLandingPage() {
  const classes = useStyles();
  const router = useRouter();
  const { authState, authMethods } = useSecurity();
  const { oktaAuth, authState: oktaAuthState } = useOktaAuth();

  async function signInWithRedirect() {
    await oktaAuth.clearDPoPStorage();
    await oktaAuth.signInWithRedirect();
  }

  if (
    router.location.pathname === '/auth/landing' &&
    authState.isAuthenticated &&
    oktaAuthState?.isAuthenticated
  ) {
    router.history.push('/');
  }

  if (authState.isAuthenticated) {
    return null;
  }

  if (authState.error) {
    return (
      <PageAuth>
        <ErrorDialog onBack={authMethods.removeError} />
      </PageAuth>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>Authentication</title>

        <style type="text/css">{`
        .main{
            display: flex;
   flex-direction: column;
        }
    `}</style>
      </Helmet>
      <Paper className={classes.paper}>
        <Box>
          <Box className={classes.banner}>
            <Stack justifyContent="space-between">
              <Box className={classes.logo} />
              <Anchor to="/help" style={{ fontSize: 16, fontWeight: 600, textDecoration: 'none' }}>
                Help
              </Anchor>
            </Stack>
            <Box className={classes.titleBox}>
              <Typography
                component="h1"
                variant="h2"
                className={classes.title}
              >
                Welcome to abrdn Connect
              </Typography>
              <Typography component="h2" variant="h4">
                Our dedicated service platform for institutional investors
              </Typography>
              <Stack spacing={2}>
                <Button
                  className={classes.loginButton}
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={signInWithRedirect}
                >
                  Log In
                </Button>
                <Button
                  className={classes.registerButton}
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                  component={Link}
                  to="/auth/register"
                >
                  Register
                </Button>
              </Stack>
            </Box>
            <Box className={classes.personalInvestor}>
              <Stack alignItems="flex-start" spacing={3}>
                <Box>
                  <Box className={classes.personalInvestorImg} />
                </Box>
                <Box>
                  <Typography className={classes.personalInvestorTitle}>
                    Looking for your personal investments?
                  </Typography>
                  <Typography variant="body2">
                    Details on your abrdn personal investments can be found in our individual investor site.
                  </Typography>
                  <a
                    rel="noopener noreferrer"
                    href="https://www.abrdn.com/"
                    className={classes.personalInvestorLink}
                  >
                    Explore our personal investment solutions
                  </a>
                </Box>
              </Stack>
            </Box>
          </Box>
          <Box className={classes.bottomContainer} mb={13}>
          <Box className={classes.createdForYou} mt={9}>
            <Typography variant="h5">
              Connect was created with you in mind
            </Typography>
            <Typography>
              Institutional investors are an essential part of our business, we created
              {' '}
              <span className={classes.noWrap}>abrdn Connect</span>
              {' '}
              to allow you to securely access data on your holdings with us.
            </Typography>
          </Box>
          <Box mt={9}>
            <Box className={classes.tilesContainer}>
              <FeatureTile
                icon="portfolio"
                title="Explore our portfolio"
                text="View the details of all our funds to make informed investments."
              />
              <FeatureTile
                icon="holdings"
                title="View your holdings"
                text="See up-to-date valuations for your holdings and accounts here."
              />
              <FeatureTile
                icon="tasks"
                title="Tasks & activities"
                text="Raise requests and complete tasks to manage your abrdn relationship."
              />
              <FeatureTile
                icon="reporting"
                title="View regular reporting"
                text="Document library where your reporting is available."
              />
            </Box>
          </Box>
        </Box>
        </Box>
        <Box className={classes.footer}>
          <Typography className={classes.allRightsReserved}>
            © 2024 abrdn. All Rights Reserved.
          </Typography>
          <Box mb={4}>
            <Stack spacing={3}>
              <Anchor to="/terms-and-conditions" style={{ fontSize: 12, lineHeight: '24px', color: '#fff', textDecoration: 'none' }}>
                Terms & Conditions
              </Anchor>
              <a
                rel="noopener noreferrer"
                href="https://www.abrdn.com/corporate/cookie-policy"
                target="_blank"
                style={{ fontSize: 12, lineHeight: '24px', color: '#fff', textDecoration: 'none' }}
              >
                Cookie Policy
              </a>
              <a
                rel="noopener noreferrer"
                href="https://www.abrdn.com/en/uk/institutional/privacy"
                target="_blank"
                style={{ fontSize: 12, lineHeight: '24px', color: '#fff', textDecoration: 'none' }}
              >
                Privacy
              </a>
            </Stack>
          </Box>
          <Box className={classes.risk}>
            Investment involves risk. The value of investments, and the income from them, can go down as well as up and an investor may get back less than the amount invested. Past performance is not a guide to future results.
          </Box>
        </Box>
      </Paper>
    </Fragment>
  );
}
