// TODO: comment
import {
  STATUS_DATA_LOADING,
  STATUS_INIT_ERROR,
  STATUS_SENT,
} from '@abrdn-latest/config';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import MSelect from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { getCountryList, getInvestorTypes } from 'api';
import { CountryList, InvestorTypeList } from 'api/types';
import { Anchor } from 'components/core';
import { EditIcon } from 'icons';
import { FormMessage } from 'interfaces';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePortfolioHoldings } from '../data';
import { getPublicPath } from '../../../utils/assets';

const useDialogTitleStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  heading: {
    marginBottom: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

// @ts-ignore
const DialogTitle = (props) => {
  // @ts-ignore
  const { children, onClose, ...other } = props;
  const classes = useDialogTitleStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4" className={classes.heading}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

interface FlagProps {
  country: string;
}

const useFlagStyles = makeStyles((theme) => ({
  wrapper: {
    verticalAlign: 'middle',
    border: '1px solid #ddd',
    borderRadius: 3,
    overflow: 'hidden',
    marginLeft: 2,
  },
  img: {
    position: 'relative',
    overflow: 'hidden',
    width: 24,
    height: 13,
    backgroundImage: ({ country }: FlagProps) =>
      `url("${getPublicPath(`images/flags/${country?.toUpperCase()}.png`)}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
}));

const Flag = (props: FlagProps) => {
  const classes = useFlagStyles(props);
  return (
    <Box
      component="span"
      display="inline-block"
      className={classes.wrapper}
    >
      <Box className={classes.img} />
    </Box>
  );
}

export const FundSelector = () => {
  const [countryList, setCountryList] = useState<CountryList | null>(null);
  const [investorTypes, setInvestorTypes] = useState<InvestorTypeList | null>(
    null
  );

  const { state, setViewAs } = usePortfolioHoldings();

  const { t } = useTranslation(['common']);

  // form messaging
  const [message, setMessage] = useState<FormMessage | null>(null);

  const [formStatus, setFormStatus] = useState('');

  const fetchMyAPI = useCallback(async () => {
    setFormStatus(STATUS_DATA_LOADING);

    try {
      const response = await getCountryList();

      if (response) {
        setCountryList(response);
      }

      const types = await getInvestorTypes();
      if (types) {
        setInvestorTypes(types);
      }

      setFormStatus('');
      setMessage(null);
    } catch (e) {
      setFormStatus(STATUS_INIT_ERROR);

      setMessage({
        type: 'error',
        message: t('common:forms.register.messages.loading-error'),
      });
    }
  }, []);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (ev: React.MouseEvent) => {
    ev.preventDefault();

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setViewAs(
      data.country || state.country,
      data.investorType || state.investorType
    );

    handleClose();
  };

  const [data, setData] = useState({
    investorType: state.investorType,
    country: state.country,
  });

  return (
    <Fragment>
      <Paper>
        <Container>
          <Box paddingY={2}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justify="space-between"
            >
              <Grid item xs={true}>
                <Typography variant="body2">
                  Viewing funds available to:{' '}
                  <Anchor to="#" onClick={handleClickOpen}>
                    <b>{state.investorTypeDisplay}</b>
                  </Anchor>
                  {' '}
                  in
                  <Flag country={state.country} />
                  {' '}
                  <Anchor to="#" onClick={handleClickOpen}>
                    <b>{state.countryDisplay}</b>
                  </Anchor>
                </Typography>
              </Grid>
              <Grid item>
                <Anchor onClick={handleClickOpen}>
                  Edit <EditIcon style={{ marginLeft: 5 }} />
                </Anchor>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Paper>
      {open && (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle>View funds as</DialogTitle>
          {formStatus !== STATUS_SENT &&
            formStatus !== STATUS_INIT_ERROR &&
            formStatus !== STATUS_DATA_LOADING && (
              <Fragment>
                <DialogContent dividers>
                  <Typography paragraph>
                    Please select the country and investor type you wish to view
                    these funds as.
                  </Typography>
                  <Typography paragraph>
                    Funds can only be shown in the regions they are available
                    for purchase from.
                  </Typography>

                  {message && (
                    <Box marginBottom={2}>
                      <Alert severity={message.type}>{message.message}</Alert>
                    </Box>
                  )}

                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={6}>
                      {investorTypes && investorTypes.length > 0 && (
                        <FormControl variant="outlined" fullWidth>
                          <MSelect
                            native
                            defaultValue={state.investorType}
                            onChange={(ev: any) => {
                              setData({
                                ...data,
                                investorType: ev.target.value,
                              });
                            }}
                            inputProps={{
                              name: 'invertortype',
                              id: 'invertortype',
                            }}
                          >
                            {investorTypes.map((item) => (
                              <option value={item.code} key={`${item.code}`}>
                                {item.displayText}
                              </option>
                            ))}
                          </MSelect>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {countryList && countryList.length > 0 && (
                        <FormControl variant="outlined" fullWidth>
                          <MSelect
                            native
                            defaultValue={state.country}
                            onChange={(ev: any) => {
                              setData({ ...data, country: ev.target.value });
                            }}
                            inputProps={{
                              name: 'country',
                              id: 'country',
                            }}
                          >
                            {countryList.map((item) => (
                              <option value={item.code} key={`${item.code}`}>
                                {item.displayText}
                              </option>
                            ))}
                          </MSelect>
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                </DialogContent>
                <Box padding={2}>
                  <Grid container alignItems="center" justify="space-between">
                    <Grid item>
                      <Button
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                        size="large"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        autoFocus
                        onClick={handleConfirm}
                      >
                        Confirm
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Fragment>
            )}
        </Dialog>
      )}
    </Fragment>
  );
};
