// TODO: comment
import { RegisterResponse } from 'api/types';
import { sendApiRequest } from '../utils';

export const registerAccount = async (
  /**
   *
   */
  emailAddress: string,
  /**
   *
   */
  forename: string,
  /**
   *
   */
  surname: string,
  /**
   *
   */
  regionCode: string,
  /**
   *
   */
  countryCode: string,
  /**
   *
   */

  investorTypeCode: string,
  /**
   *
   */
  termsAndConditions: string
): Promise<RegisterResponse> => {
  const data: any = {
    forename,
    surname,
    emailAddress,
    regionCode,
    countryCode,
    investorTypeCode,
    termsAndConditions: {
      termsAndConditions,
    },
  };

  return sendApiRequest<RegisterResponse>(
    `${process.env.REACT_APP_API_URL}api/account/registration`,
    null,
    {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    }
  );
};
