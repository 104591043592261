// TODO: comment
import { States } from '@abrdn-latest/config';
import { getClientSummary } from 'api';
import { ClientAccountSummary, Party } from 'api/types';
import { sendApiRequest } from '../utils';

export type AllPartiesResponse = Party[];

/**
 * Get a list of the users accounts
 */
export const getAllParties = async (): Promise<AllPartiesResponse> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/parties/all`;

  try {
    const response = await sendApiRequest<AllPartiesResponse>(endpoint);

    // return [...response, ...response, ...response, ...response, ...response]
    //   .sort((a, b) => {
    //     return a.preferredName.localeCompare(b.preferredName);
    //   })
    //   .slice(0, 16);

    return response;
  } catch (e) {
    return [];
  }
};

export const getDocumentOnlyParties = async (): Promise<AllPartiesResponse> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/parties/documentsonly/all`;

  return await sendApiRequest<AllPartiesResponse>(endpoint);
};

export interface UserClientDetail extends Party, ClientAccountSummary {
  state: string;
}

export type UserClientDetails = UserClientDetail[];

let clientList: UserClientDetails;

/**
 * Get a list of parties and client details if populated
 */
export const getUsersClientDetails = async (): Promise<UserClientDetails> => {
  if (clientList) {
    return clientList;
  }

  // get all the parties
  const parties = await getAllParties();

  //
  const blankList: UserClientDetail = {
    ...{ id: '', preferredName: '', currencyCodes: [], clientReference: '' },
    ...{
      state: States.Idle,
      accounts: [],
      client: {
        id: '',
        name: '',
      },
      currencyCodes: [],
      valuation: {
        currencyCode: '',
        date: '',
        value: 0,
      },
    },
  };

  clientList = [];

  // loop through all the parties and populate them with blank data
  for (let counter = 0; counter < parties.length; counter++) {
    clientList.push({ ...blankList, ...parties[counter] });
  }

  return clientList;
};

export interface DocumentOnlyClientDetail extends UserClientDetail {
  documentOnly: true;
}

export type DocumentOnlyClientDetails = DocumentOnlyClientDetail[];

let documentOnlyClientList: DocumentOnlyClientDetails;

export const getDocumentOnlyClientDetails = async (): Promise<DocumentOnlyClientDetails> => {
  if (documentOnlyClientList) {
    return documentOnlyClientList;
  }

  // get document only parties
  const documentOnlyParties = await getDocumentOnlyParties();

  //
  const blankList: UserClientDetail = {
    ...{ id: '', preferredName: '', currencyCodes: [], clientReference: '' },
    ...{
      state: States.Idle,
      accounts: [],
      client: {
        id: '',
        name: '',
      },
      currencyCodes: [],
      valuation: {
        currencyCode: '',
        date: '',
        value: 0,
      },
    },
  };

  documentOnlyClientList = [];

  // loop through parties and populate them with blank data
  for (const documentOnlyParty of documentOnlyParties) {
    documentOnlyClientList.push({
      ...blankList,
      ...documentOnlyParty,
      documentOnly: true,
    });
  }

  return documentOnlyClientList;
};

/**
 *
 */
export const getPartiallyPopulatedClients = async (): Promise<any> => {
  // get all the parties
  const clients = await getUsersClientDetails();
  const partiallyPopulatedClients = []

  const clientDetails = await Promise.allSettled(
    clients.map(client => {
      return client.state === States.Idle
        ? getClientSummary(client.id)
        : null;
    })
  );

  // loop through the parties, to populate
  for (let i = 0; i < clients.length; i++) {
    const client = clients[i];
    const detail = clientDetails[i];

    if (detail.status === 'fulfilled') {
      if (detail.value) {
        partiallyPopulatedClients.push({
          ...client,
          ...detail.value,
          state: States.Success,
        });
      } else {
        partiallyPopulatedClients.push(client);
      }
    } else {
      partiallyPopulatedClients.push({
        ...client,
        state: States.Error,
      });
    }
  }

  clientList = partiallyPopulatedClients;

  return partiallyPopulatedClients;
};
