import {
  DocumentOnlyClientDetails,
  getClientSummary, getDocumentOnlyClientDetails, getPartiallyPopulatedClients,
  getUsersClientDetails,
  UserClientDetails
} from 'api';
import { ClientAccountSummary } from 'api/types';
import { useSecurity } from 'authentication';
import React, { useEffect, useState } from 'react';
import { ClientHoldingsProvider } from './';

interface Props {
  /**
   *
   */
  children: React.ReactNode;
}

let cache: any = {};

/**
 * Client Holdings universal methods
 */
export const ClientHoldings = ({ children }: Props) => {
  const { authState, settings } = useSecurity();

  // object to hold details on the
  const clientDetails: { [key: string]: ClientAccountSummary } = {};

  // all the client holdings that the user has
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState<UserClientDetails>([]);
  const [documentOnlyClients, setDocumentOnlyClients] =
    useState<DocumentOnlyClientDetails>([]);

  // action to keep track of the account currencies available for all funds an holdings
  const [clientCurrencies, setClientCurrenciesState] = useState<any>({});

  const setClientCurrencies = (
    clientId: string,
    accountCurrencies: string[],
    prepend: boolean = false
  ): void => {
    // get any currencies that have already been populated
    const stored = cache[clientId] || [];

    // combine the currencies
    const combined = [
      ...new Set(
        prepend
          ? [...accountCurrencies, ...stored]
          : [...stored, ...accountCurrencies]
      ),
    ];

    // update the currencies
    cache = {
      ...clientCurrencies,
      ...{
        [clientId]: combined,
      },
    };

    if (cache !== clientCurrencies[clientId]) {
      setClientCurrenciesState(cache);
    }
  };

  /**
   * This is only supposed to be called on the homepage for the overview,
   * we do it this way so the data can be stored and doesn't need to be re-requested
   * @param clientId
   */
  const getClientDetails = async (
    /**
     * Gets or sets the client identifier.
     */
    clientId: string,
    /**
     * Gets or sets the valuation date.
     */
    valuationDate?: Date | null | undefined,
    /**
     * Gets or sets the currency code.
     */
    currencyCode?: string | null
  ): Promise<ClientAccountSummary | null> => {
    // if the client details have already been requested
    if (clientDetails[clientId] && !valuationDate && !currencyCode) {
      return clientDetails[clientId];
    }

    try {
      // get the client details
      const details = await getClientSummary(
        clientId,
        valuationDate,
        currencyCode
      );

      // if the request was successful
      if (details) {
        // record the client detail
        clientDetails[clientId] = details;

        // return the retails back to the user
        return details;
      }
    } catch (e) {}

    // return no details
    return null;
  };

  const getClientHoldingSummary = async (
    /**
     * Gets or sets the client identifier.
     */
    clientId: string,
    /**
     * Gets or sets the valuation date.
     */
    valuationDate?: Date | null | undefined,
    /**
     * Gets or sets the currency code.
     */
    currencyCode?: string | null
  ): Promise<ClientAccountSummary | null> => {
    try {
      // get the client details
      const details = await getClientSummary(
        clientId,
        valuationDate,
        currencyCode
      );

      // TODO: process the request

      return details;
    } catch (e) {}

    // return no details
    return null;
  };

  // on load, load the holdings, it's important to know how many holdings the
  // client has, so we can hide navigation if there are none, or make the loading
  // on the homepage a little more efficient
  useEffect(() => {
    const load = async () => {
      try {
        // feature detection
        if (settings['ClientHoldings.Enabled']) {
          // load all the client holdings assiciated with the user
          const [clients, documentOnlyClients] = await Promise.all([
            getUsersClientDetails(),
            getDocumentOnlyClientDetails(),
          ]);

          setClients(clients);
          setDocumentOnlyClients(documentOnlyClients);
        }
      } catch (e) {} finally {
        setLoading(false);
      }
    };

    if (authState.isAuthenticated) {
      load();
    }
  }, [authState.isAuthenticated]);

  useEffect(() => {
    const load = async () => {
      try {
        // feature detection
        if (settings['ClientHoldings.Enabled']) {
          // load all the client holdings assiciated with the user with details
          const clients = await getPartiallyPopulatedClients();

          setClients(clients);
        }
      } catch (e) {} finally {
        setLoading(false);
      }
    };

    if (!loading) {
      load();
    }
  }, [loading]);

  // wrapping component
  return (
    <ClientHoldingsProvider
      value={{
        loading,
        clients,
        documentOnlyClients,
        getClientDetails,
        getClientHoldingSummary,
        clientCurrencies,
        setClientCurrencies,
      }}
    >
      {children}
    </ClientHoldingsProvider>
  );
};
