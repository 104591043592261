import { sendApiRequest } from '../utils';

interface GetUserTaskCountResponse {
  taskCount: number;
}

export async function getUserTaskCount(userId: string): Promise<GetUserTaskCountResponse> {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/clientprovisioning/GetUserTaskCount/${userId}`;
  return sendApiRequest<GetUserTaskCountResponse>(endpoint);
}
