// TODO: comment
import { Banner } from '@abrdn';
import { GREY_DARK, STATUS_FAILED, STATUS_LOADING } from '@abrdn-latest/config';
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getPortfolioHoldingDownloadUri, getSavedFundList } from 'api';
import { Fund } from 'api/types';
import { downloadFile } from 'api/utils';
import { Anchor } from 'components/core';
import { DataError, Loading, NoData } from 'components/no-data';
import { PopupState } from 'components/popup-app';
import { ArrowDownwardIcon, CloseIcon } from 'icons';
import { toast } from 'material-react-toastify';
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import {
  Banner as PortfolioBanner,
  Disclaimer,
  FundListing,
  FundSelector,
} from './components';
import { usePortfolioHoldings } from './data';
import { getPublicPath } from '../../utils/assets';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const AppliedFilters = () => {
  const { state, setAssetFunds, setFundRanges, setProductTypes, clearFilters } =
    usePortfolioHoldings();

  const classes = useStyles();

  if (
    state.assetFundClass.length === 0 &&
    state.fundRange.length === 0 &&
    state.productType.length === 0
  ) {
    return null;
  }

  return (
    <Fragment>
      <Box className={classes.root} marginY={4}>
        {state.assetFundClass.map((item, index: number) => {
          return (
            <Chip
              variant="outlined"
              label={item}
              onDelete={() => {
                setAssetFunds(state.assetFundClass.filter((f) => f !== item));
              }}
              deleteIcon={<CloseIcon />}
              key={`af-${index}`}
            />
          );
        })}

        {state.fundRange.map((item, index: number) => {
          return (
            <Chip
              variant="outlined"
              label={item}
              onDelete={() => {
                setFundRanges(state.fundRange.filter((f) => f !== item));
              }}
              deleteIcon={<CloseIcon />}
              key={`fr-${index}`}
            />
          );
        })}

        {state.productType.map((item, index: number) => {
          return (
            <Chip
              variant="outlined"
              label={item}
              onDelete={() => {
                setProductTypes(state.productType.filter((f) => f !== item));
              }}
              deleteIcon={<CloseIcon />}
              key={`pt-${index}`}
            />
          );
        })}

        <Chip color="primary" label="Clear all" onClick={clearFilters} />
      </Box>
    </Fragment>
  );
};

const PagePortfolioHoldings = () => {
  const { funds, status } = usePortfolioHoldings();

  const { t } = useTranslation(['common']);

  // config
  const PAGE_SIZE: number = 25;

  // is the compae window open
  const [compareState, setCompareState] = useState<PopupState>('closed');

  // store the selected funds
  const [selectedFunds, setSelectedFunds] = useState<string[]>([]);

  // saved funds
  const [userSavedFunds, setUserSavedFunds] = useState<string[]>([]);

  // fake pagination
  const [page, setPage] = useState<number>(0);

  const isWide = useMedia('(min-width: 600px)');

  useEffect(() => {
    onFundSave();
  }, []);

  const onFundSave = async () => {
    const saved = await getSavedFundList();
    setUserSavedFunds(saved);
  };

  // watch for the funds list changing, this will most likely be changed from updating and filtering
  useEffect(() => {
    // reset the page back to the first one
    setPage(0);
  }, [funds]);

  /**
   * handle pagination
   */
  const handleLoadMore = () => {
    // load the next page
    setPage(page + 1);
  };

  /**
   *
   * @param fundCode
   * @returns
   */
  const getFundNameFromCode = (fundCode: string): string => {
    if (funds) {
      for (let item in funds) {
        if (funds[item].fundCode === fundCode) {
          return funds[item].fundName;
        }
      }

      return fundCode;
    }
    return '';
  };

  /**
   * Download all the selected files
   */
  const handleExport = async () => {
    const failedDownloads: any[] = [];

    // loop through all the selected funds and trigger a download
    await Promise.all(
      selectedFunds.map(async (fundCode: string) => {
        // try and download the file
        const result = await downloadFile(
          getPortfolioHoldingDownloadUri(fundCode),
          `Latest-${getFundNameFromCode(fundCode)}.xlsx`
        );

        if (!result) {
          failedDownloads.push(fundCode);
        }
      })
    );

    if (failedDownloads.length > 0) {
      if (failedDownloads.length === selectedFunds.length) {
        toast.error(
          `Failed to export fund${failedDownloads.length > 1 ? 's' : ''}`
        );
      } else {
        toast.error(
          `Failed to export ${failedDownloads.length} document${
            failedDownloads.length > 1 ? 's' : ''
          }`
        );
      }
    }
  };

  /**
   *
   * @param selected
   */
  const handleFundChecked = (selected: string[]): void => {
    setSelectedFunds(selected);
  };

  // filter the saved funds, we do this so we can offset the results of calculations
  const savedFunds =
    funds &&
    funds.filter((fund: Fund) => {
      return userSavedFunds.includes(fund.fundCode);
    });

  /**
   * Calculation to work out the data items that are presented on the screen
   * @returns string
   */
  const resultsLabel = () => {
    if (funds) {
      return `Showing ${Math.min(
        Math.min((page + 1) * PAGE_SIZE, funds.length) +
          (savedFunds ? savedFunds.length : 0),
        funds.length
      )} of ${funds.length} results`;
    }
    return '';
  };

  return (
    <Fragment>
      <Helmet>
        <title>{t('common:titles.portfolio-holdings')}</title>
      </Helmet>

      {/* banner */}
      <Banner imagePath={getPublicPath('images/backgrounds/portfolio-holdings.jpg')}>
        <PortfolioBanner />
      </Banner>

      {/* fund type selector (to tie in with the portfolio banner) */}
      <FundSelector />

      {/* fund listing */}
      <Paper style={{ backgroundColor: GREY_DARK }}>
        <Box paddingTop={6} paddingBottom={6}>
          <Container>
            {/* results */}

            <Fragment>
              {funds && funds.length ? (
                // header area
                <Box marginBottom={4}>
                  <Grid
                    container
                    spacing={3}
                    alignContent="center"
                    alignItems="stretch"
                  >
                    {/* results x of y */}
                    <Grid item xs={12} sm={6}>
                      <Typography>{resultsLabel()}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box textAlign="right">
                        {/* deselect funds */}
                        <Anchor
                          disabled={selectedFunds.length === 0}
                          onClick={() => {
                            setSelectedFunds([]);
                          }}
                        >
                          Select none
                        </Anchor>

                        {/* export functionality */}
                        <Anchor
                          disabled={selectedFunds.length === 0}
                          onClick={handleExport}
                          style={{ marginLeft: 12 }}
                        >
                          Download
                        </Anchor>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Fragment />
              )}

              <AppliedFilters />

              {/* loading state */}
              {status === STATUS_LOADING && !funds && (
                <Loading>
                  <Typography paragraph>Loading funds</Typography>
                </Loading>
              )}

              {/* error */}
              {status === STATUS_FAILED && (
                <DataError>
                  <Typography paragraph>
                    Something went wrong. Please try again.
                  </Typography>
                </DataError>
              )}

              {funds && funds.length ? (
                <Fragment />
              ) : (
                <Fragment>
                  {status !== STATUS_LOADING && (
                    <NoData>
                      <Typography paragraph>No funds to show</Typography>
                    </NoData>
                  )}
                </Fragment>
              )}

              {/* saved fund listing */}
              {savedFunds && savedFunds.length > 0 && (
                <Box paddingBottom={2}>
                  <FundListing
                    small={!isWide}
                    title="My Favourites"
                    selected={selectedFunds}
                    onChange={handleFundChecked}
                    onFundSave={onFundSave}
                    saved={userSavedFunds}
                    funds={savedFunds}
                  />
                </Box>
              )}

              {funds && (
                <Fragment>
                  {/* all funds (no including saved funds) */}
                  <FundListing
                    small={!isWide}
                    title="All funds"
                    selected={selectedFunds}
                    onChange={handleFundChecked}
                    onFundSave={onFundSave}
                    saved={userSavedFunds}
                    funds={funds
                      .filter((fund: Fund) => {
                        return !userSavedFunds.includes(fund.fundCode);
                      })
                      .slice(0, (page + 1) * PAGE_SIZE)}
                  />

                  {/* load more button */}
                  {Math.min((page + 1) * PAGE_SIZE, funds.length) <
                    funds.length && (
                    <Box paddingY={2} textAlign="center">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleLoadMore}
                        endIcon={<ArrowDownwardIcon />}
                      >
                        Load More
                      </Button>
                    </Box>
                  )}
                </Fragment>
              )}
            </Fragment>
          </Container>

          {/* shared disclaimer message */}
          <Disclaimer />
        </Box>
      </Paper>
    </Fragment>
  );
};

export default PagePortfolioHoldings;
