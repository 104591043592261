import { DocumentOnlyClientDetails, UserClientDetails } from 'api';
import { ClientAccountSummary } from 'api/types';
import { createContext, useContext } from 'react';

// Props
interface Context {
  clientCurrencies: any;

  clients: UserClientDetails;
  documentOnlyClients: DocumentOnlyClientDetails;
  getClientDetails(
    /**
     * Gets or sets the client identifier.
     */ clientId: string
  ): Promise<ClientAccountSummary | null>;
  getClientHoldingSummary(
    /**
     * Gets or sets the client identifier.
     */
    clientId: string,
    /**
     * Gets or sets the valuation date.
     */
    valuationDate?: Date | null | undefined,
    /**
     * Gets or sets the currency code.
     */
    currencyCode?: string | null
  ): Promise<ClientAccountSummary | null>;

  loading: boolean;

  setClientCurrencies(
    clientId: string,
    accountCurrencies: string[],
    prepend?: boolean
  ): void;
}

const initialState: Context = {
  loading: true,
  clients: [],
  documentOnlyClients: [],
  getClientDetails: async (
    clientId: string
  ): Promise<ClientAccountSummary | null> => {
    return null;
  },

  getClientHoldingSummary: async (
    /**
     * Gets or sets the client identifier.
     */
    clientId: string,
    /**
     * Gets or sets the valuation date.
     */
    valuationDate?: Date | null | undefined,
    /**
     * Gets or sets the currency code.
     */
    currencyCode?: string | null
  ): Promise<ClientAccountSummary | null> => {
    return null;
  },

  clientCurrencies: {},

  setClientCurrencies: (
    clientId: string,
    accountCurrencies: string[],
    prepend: boolean = false
  ): void => {},
};

// defaults
const ClientHoldingsContext = createContext<Context>(initialState);

// helper method to get the context
export const useClientHoldings = (): Context =>
  useContext(ClientHoldingsContext);

// shortcut to the provider
export const ClientHoldingsProvider = ClientHoldingsContext.Provider;
