import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';

const useStyles = makeStyles(() => ({
  inner: {
    display: 'block',
    position: 'relative',
    padding: 0,
    paddingBottom: '56.25%',
    height: 0,
    minHeight: '200px',
    overflow: 'hidden',
  },
  iframe: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    border: 0,
    width: '100%',
    height: '100%',
  },
}));

interface Props {
  src: string;
  title: string;
}

export const EmbeddedVideo = ({ src, title }: Props) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.inner} dangerouslySetInnerHTML={{
        __html: `
          <iframe
            class="${classes.iframe}"
            title="${title}"
            allow="fullscreen"
            src="${src}"
            allowfullscreen
          />
        `
      }} />
    </Box>
  );
};
