// TODO: comment
import {
  STATUS_FAILED,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from '@abrdn-latest/config';
import { Button, Paper, Typography } from '@material-ui/core';
import { getPartiesPerPage } from 'api/constants';
import { useSecurity } from 'authentication';
import { MyAccountsTable } from 'components/my-accounts';
import { DataError, Loading, NoData } from 'components/no-data';
import { Progress } from 'components/ui';
import { PersonIcon, RefreshIcon } from 'icons';
import { toast } from 'material-react-toastify';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getParties,
  selectParties,
  selectPartiesStatus,
} from 'redux/reducers/account';
import { delayFire } from 'utils';

export const AccountsListing = () => {
  const details = useSelector(selectParties);

  // loading state
  const loadingState = useSelector(selectPartiesStatus);

  const dispatch = useDispatch();
  const { authState } = useSecurity();

  useEffect(() => {
    if (authState.isAuthenticated) {
      delayFire(() => {
        refreshView(false);
      });
    }
  }, [authState.isAuthenticated]);

  const refreshView = (isRefresh: boolean = true) => {
    dispatch(
      getParties({
        start: 0,
        max: getPartiesPerPage(),
      })
    );
    // set up a refresh interval to check for new messages
    if (isRefresh) {
      toast.dark(<Progress label={`Refreshing Accounts`} />, {
        closeButton: false,
        autoClose: 4000,
      });
    }
  };

  const onPagination = (page: number, rows: number): void => {
    dispatch(
      getParties({
        start: page * rows,
        max: rows,
      })
    );
  };

  return (
    <Fragment>
      {details && details.parties.length > 0 ? (
        <Fragment>
          <MyAccountsTable
            title={'My Accounts'}
            data={details}
            onPagination={onPagination}
          />
        </Fragment>
      ) : (
        <Paper>
          {loadingState === STATUS_FAILED && (
            <DataError>
              <Typography paragraph>
                Something went wrong. Please try again.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  refreshView();
                }}
                endIcon={<RefreshIcon />}
              >
                Refresh
              </Button>
            </DataError>
          )}
          {loadingState === STATUS_LOADING && <Loading />}
          {loadingState === STATUS_SUCCESS && (
            <NoData icon={PersonIcon}>
              <Typography paragraph>No accounts to view</Typography>
            </NoData>
          )}
        </Paper>
      )}
    </Fragment>
  );
};
