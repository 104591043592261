import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { AuthLandingPage } from './AuthLandingPage';
import { RegisterForm } from '../../authentication';

export const AuthRoutes = () => {
  return (
    <Fragment>
      <Route exact path="/auth/landing" component={AuthLandingPage} />

      <Route exact path="/auth/register" component={RegisterForm} />
    </Fragment>
  );
};
