// TODO: comment

import '../messages/PageMessages.scss';

import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import { DataError, Loading, NoData } from 'components/no-data';
import { DocumentUploadIcon, FilterListIcon, RefreshIcon } from 'icons';
import { DocumentsTable, NewDocumentForm } from './components';
import { PopupApp, PopupState } from 'components/popup-app';
import React, { Fragment, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { SERVER_ACTION_DELAY, getDocumentsPerPage } from 'api/constants';
import {
  STATUS_FAILED,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from '@abrdn-latest/config';
import {
  addDeletedDocumentId,
  getAllDocuments,
  getDocumentCategories,
  selectDocumentCategories,
  selectDocuments,
  selectDocumentsStatus,
} from 'redux/reducers/documents';
import { useDispatch, useSelector } from 'react-redux';

import { Banner } from '@abrdn';
import { Helmet } from 'react-helmet';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Progress } from 'components/ui';
import { SlideOut } from 'components/slideout';
import { Stack } from '@abrdn-latest/core';
import { delayFire } from 'utils';
import { deleteDocuments } from 'api';
import { toast } from 'material-react-toastify';
import { useClientHoldings } from 'pages/client-holdings/data';
import { useSecurity } from 'authentication';
import { useTranslation } from 'react-i18next';
import { useWidth } from '@abrdn-latest/use';
import { getPublicPath } from '../../utils/assets';

interface Params {
  category?: string;
}

const PageDocuments = (props: any) => {
  const { t } = useTranslation(['common']);

  const { clients, documentOnlyClients } = useClientHoldings();
  const allClients = [...clients, ...documentOnlyClients];

  // Should the upload be a popup or not
  const UPLOAD_POPUP: boolean = true;

  // upload documents poppup
  const [uploadPopupState, setUploadPopupState] =
    useState<PopupState>('closed');

  const { isMobile } = useWidth();

  const categories = useSelector(selectDocumentCategories);

  const dispatch = useDispatch();
  const { authState } = useSecurity();

  useEffect(() => {
    if (authState.isAuthenticated) {
      delayFire(() => {
        if (categories.length === 0) {
          dispatch(getDocumentCategories());
        }
      });
    }
  }, [authState.isAuthenticated]);

  // get the query params
  //cconst query = useQuery();

  // get the possible routes
  const params: Params = useParams();

  // get the filter category
  const category: string | undefined = params.category || undefined;

  // status
  const loadingState = useSelector(selectDocumentsStatus);
  // documents
  const documents = useSelector(selectDocuments);

  const [cat, setCat] = useState<string | undefined>(category);
  const [tableTitle, setTableTitle] = useState<string | undefined>();

  const handleDelete = async (selected: Array<string>) => {
    const ids = await deleteDocuments(selected);
    //
    for (let counter: number = 0; counter < ids.length; counter++) {
      dispatch(addDeletedDocumentId(ids[counter]));
    }

    // wait a few seconds, before trying to refresh the view
    setTimeout(() => {
      // refresh the current list
      refreshView();
    }, SERVER_ACTION_DELAY);
  };

  /**
   * Handle pagination
   * @param page
   * @param rows
   */
  const onPagination = (page: number, rows: number): void => {
    dispatch(
      getAllDocuments({
        start: page * rows,
        max: rows,
        documentTypeCode: cat,
        clientId,
      })
    );
  };

  /**
   * Refresh the list
   * @param isRefresh
   */
  const refreshView = (isRefresh: boolean = true) => {
    if (window.refreshInterval) {
      clearInterval(window.refreshInterval);
    }

    // reload the message list

    dispatch(
      getAllDocuments({
        start: 0,
        max: getDocumentsPerPage(),
        documentTypeCode: cat,
        clientId,
      })
    );

    // set up a refresh interval to check for new messages
    // TODO: re-enable this
    // refreshInterval = setInterval(refreshView, LIST_REFRESH_INTERVAL);

    if (isRefresh) {
      toast.dark(<Progress label={`Refreshing Documents`} />, {
        closeButton: false,
        autoClose: 4000,
      });
    }
  };

  // when the view initially loads, request the messages
  useEffect(() => {
    if (authState.isAuthenticated) {
      delayFire(() => {
        refreshView(false);
      });
    }

    // when the view is unloaded, perform a clean up
    return () => {
      if (window.refreshInterval) {
        clearInterval(window.refreshInterval);
      }
    };
  }, [authState.isAuthenticated]);

  const ALL_TITLE: string = 'All Documents';

  /**
   *
   * @param cat
   * @param label
   */
  const onNavigation = (cat: string, label: string) => {
    setCat(cat);
    setTableTitle(label || ALL_TITLE);

    props.history.replace({
      pathname: cat ? `/documents/${cat}` : `/documents/all`,
    });

    dispatch(
      getAllDocuments({
        start: 0,
        max: getDocumentsPerPage(),
        documentTypeCode: cat,
        clientId,
      })
    );
  };

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (ev: any) => {
    if (ev.type === 'keydown' && (ev.key === 'Tab' || ev.key === 'Shift')) {
      return;
    }

    setDrawerOpen(open);
  };

  const handleAccountChange = (ev: any) => {
    setClientId(ev.target.value);
  };

  const location = useLocation<{ clientId?: string }>();

  const initialClientId = location.state?.clientId ?? 'all';
  const [clientId, setClientId] = React.useState<string>(initialClientId);

  React.useEffect(() => {
    dispatch(
      getAllDocuments({
        start: 0,
        max: getDocumentsPerPage(),
        documentTypeCode: cat,
        clientId,
      })
    );
  }, [clientId]);

  const Navigation = () => {
    return (
      <Box>
        {allClients.length > 1 && (
          <Box>
            <FormControl
              variant="outlined"
              style={{ maxWidth: 320, width: '100%' }}
            >
              <Select fullWidth value={clientId} onChange={handleAccountChange}>
                <MenuItem value="all">All accounts</MenuItem>

                {allClients.map(client => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.preferredName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <List component="div">
          <ListItem
            button
            component={RouterLink}
            selected={category === undefined || category === 'all'}
            to="/documents/"
            onClick={(ev: React.MouseEvent) => {
              ev.preventDefault();

              if (onNavigation) {
                onNavigation('', '');
              }
            }}
          >
            <ListItemText
              primary={ALL_TITLE}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>

          {categories.map((item: any, index: number) => {
            return (
              <ListItem
                key={`li-${index}`}
                button
                component={RouterLink}
                selected={
                  item.value === category ||
                  (item.value === 'all' && category === undefined)
                }
                to={`/documents/${item.value}`}
                onClick={(ev: React.MouseEvent) => {
                  ev.preventDefault();

                  if (onNavigation) {
                    onNavigation(item.value, item.displayText);
                  }
                }}
              >
                <ListItemText
                  primary={item.displayText}
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
    );
  };

  const handleClick = (ev: React.MouseEvent) => {
    if (UPLOAD_POPUP) {
      ev.preventDefault();
      setUploadPopupState(uploadPopupState === 'open' ? 'minimised' : 'open');
    }
  };

  const handleRefreshClick = (ev: React.MouseEvent) => {
    refreshView();
  };

  return (
    <Fragment>
      <Helmet>
        <title>{t('common:titles.documents')}</title>
      </Helmet>

      <Banner imagePath={getPublicPath('images/backgrounds/documents.jpg')}>
        <Typography variant="h3" component="h1">
          {t('common:titles.documents')}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/document/new"
            size="large"
            endIcon={<DocumentUploadIcon />}
            onClick={handleClick}
          >
            Upload documents
          </Button>

          <Button
            color="primary"
            variant="outlined"
            size="large"
            disabled={loadingState === STATUS_LOADING}
            onClick={handleRefreshClick}
            endIcon={<RefreshIcon />}
          >
            Refresh
          </Button>
        </Stack>
      </Banner>

      <Box paddingY={6} style={{ backgroundColor: '#eee' }}>
        <Container>
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} md={3}>
              {isMobile ? (
                <Fragment>
                  <Button
                    fullWidth
                    onClick={() => {
                      setDrawerOpen(!drawerOpen);
                    }}
                    variant="outlined"
                    size="large"
                    endIcon={<FilterListIcon />}
                  >
                    Filter
                  </Button>

                  <SlideOut
                    title="Filter Documents"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                  >
                    <Navigation />
                  </SlideOut>
                </Fragment>
              ) : (
                <Navigation />
              )}
            </Grid>
            <Grid item xs={12} md={9}>
              <Box className="sticky-column">
                {documents?.documents.length > 0 && loadingState === STATUS_SUCCESS ? (
                  <DocumentsTable
                    title={tableTitle || ALL_TITLE}
                    canDelete={cat === 'CUD' || !cat || cat === 'all'}
                    onDelete={handleDelete}
                    data={documents}
                    onPagination={onPagination}
                  />
                ) : (
                  <Paper>
                    {loadingState === STATUS_FAILED && (
                      <DataError>
                        <Typography paragraph>
                          Something went wrong. Please try again.
                        </Typography>
                        <Button
                          variant="contained"
                          onClick={() => {
                            refreshView();
                          }}
                          endIcon={<RefreshIcon />}
                        >
                          Refresh
                        </Button>
                      </DataError>
                    )}
                    {loadingState === STATUS_LOADING && <Loading />}
                    {loadingState === STATUS_SUCCESS && (
                      <NoData>
                        <Typography paragraph>No documents to view</Typography>
                      </NoData>
                    )}
                  </Paper>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* upload documens popup */}
      {UPLOAD_POPUP && (
        <PopupApp
          state={uploadPopupState}
          showUIMinimise
          title={'Upload'}
          maxHeight={380}
          onClose={() => {
            setUploadPopupState('closed');
          }}
          onStateChange={(state) => {
            setUploadPopupState(state);
          }}
        >
          <Container disableGutters maxWidth="xs">
            <NewDocumentForm />
          </Container>
        </PopupApp>
      )}
    </Fragment>
  );
};

export default PageDocuments;
